import {
    Button,
    Checkbox,
    Modal,
    Typography,
    Input,
  } from "antd";
  import { useState } from "react";
  import {
    QuestionCircleOutlined,
  } from "@ant-design/icons";
  import { useNavigate } from "react-router-dom";
  import "../../AirlineCredentialFlow.scss";
  import image from "../../Page-1.svg";
  import confirmImage from "../../image 36.jpg";
  import checks from "./checks.svg";
  import select from "./select.svg";
  import TextArea from "antd/es/input/TextArea";
  import Logo from 'static/images/Finkr@ftLogo.png'
  
  export default function AirlinePAN(props: any) {
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(true);
    const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
    const [isCustomSelected, setIsCustomSelected] = useState(false); // State for "Custom" option
  
    const handleBack = () => navigate(-1);
  
    const handleContinue = () => navigate("/credential/gst/landing");
  
    const showModal = () => setIsModalVisible(true);
  
    const handleModalClose = () => setIsModalVisible(false);
  
    const issues = [
      "Issue_01_regarding some common thing",
      "Issue_02_regarding GST cred",
      "Issue_03_regarding Airline",
      "Issue_04_regarding sign up",
      "Issue_05_regarding some common thing",
      "Custom"
    ];
  
    const handleCheckboxChange = (issue: string) => {
      if (issue === "Custom") {
        setIsCustomSelected(!isCustomSelected);
        if (!isCustomSelected) {
          setSelectedIssues([issue]); 
        } else {
          setSelectedIssues([]); 
        }
      } else {
        setSelectedIssues((prev) =>
          prev.includes(issue)
            ? prev.filter((item) => item !== issue)
            : [...prev, issue]
        );
      }
    };
  
    const dropdownContent = (
      <div
        style={{
          border: "1px solid ##E8EAF1",
          borderRadius: "4px",
          marginTop: "8px",
          padding: "16px 16px",
          backgroundColor: "#F8F9FB",
          display: "flex",
          gap: "16px",
          flexDirection: "column",
        }}
      >
        {issues.map((issue, index) => (
          issue === "Custom" || !isCustomSelected ? ( // Show all options except "Custom" if not selected
            <div style={{ display: "flex", gap: "15px" }} key={index}>
              <Checkbox
                checked={selectedIssues.includes(issue)}
                onChange={() => handleCheckboxChange(issue)}
              />
              <Typography>{issue}</Typography>
            </div>
          ) : null
        ))}
      </div>
    );
  
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px 30px",
          }}
        >
          <div style={{ color: "#000", cursor: "pointer" }} onClick={handleBack}>
            Back
          </div>
        </div>
        <div>
          <img
            src={Logo}
            alt="Description of the SVG"
            style={{ height: "50px", width: '120px', marginLeft: "96px" }}
          />
        </div>
        <Typography
          style={{
            marginBottom: 24,
            color: "#909090",
            textAlign: "right",
            marginRight: "70px",
            fontSize:'14px'
          }}
        >
          Having trouble?{" "}
          <a style={{ color: "#0A8394", cursor: "pointer",fontSize:'14px' }} onClick={showModal}>
            Get help
          </a>
        </Typography>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={confirmImage}
              alt="Description of the SVG"
              style={{ width: "85px", height: "85px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <div>
              <Typography style={{ color: "#3F4047", marginLeft: "10px" , fontSize:'18px'}}>
                You have successfully provided the certificates
              </Typography>
  
              <Typography style={{ color: "#000", marginTop: "20px", fontSize:'18px' }}>
                Now Finkraft.ai will do the needful on your behalf
              </Typography>
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: "40px" }}>
            <Button
              type="primary"
              style={{ height: "50px", width: "400px" }}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
  
        <Modal
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          title=""
        >
          <span style={{ display: "flex", gap: "10px" }}>
            <QuestionCircleOutlined style={{ color: "#0A8394", fontSize: "24px" }} />
            <Typography
              style={{
                color: "#0A8394",
                fontSize: "24px",
                fontWeight: "400",
                fontFamily: "Noto Sans",
              }}
            >
              Help center
            </Typography>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Typography style={{ color: "#212121" }}>Choose the issue here</Typography>
            <img
              src={select}
              onClick={() => setDropdownVisible(!dropdownVisible)}
              style={{ cursor: "pointer" }}
            />
          </div>
          {dropdownVisible && dropdownContent}
  
          {/* Conditionally render the text field when "Custom" is selected */}
          {isCustomSelected && (
            <div style={{ marginTop: "10px" }}>
              <TextArea
                placeholder="Custom issue description"
                style={{ width: "100%" }}
              />
            </div>
          )}
  
          <Button
            type="primary"
            style={{
              width: "100%",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "24px",
              fontSize: "16px",
            }}
          >
            <img
              src={checks}
              style={{ width: "26px", height: "26px", marginRight: "8px" }}
            />
            Submit
          </Button>
        </Modal>
      </div>
    );
  }
  