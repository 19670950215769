import React, { useState, useEffect } from 'react';
import './CarouselComp.css'
import gstimage1 from '../../../../assets/images/gstpage1.png';  // Importing the image
import emailfollowup from '../../../../assets/images/emailfollowup.png';
import vendoreinvoice from '../../../../assets/images/vendoreinvoice.png';

const CarouselComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      image: gstimage1,  // Use the imported image here
      heading: 'Secure GST Credential Integration',
      list: [
        'Fast and secure connection to GSTN with real-time data retrieval.',
        'Ensure compliance with government-mandated data security norms.',
      ],
    },
    {
      image: vendoreinvoice, // Use another image or keep it the same
      heading: 'Vendor E-Invoice & Management insight',
      list: [
        'Clear visualisation of vendor eligibility for e-invoicing based on turnover and GST compliance.',
        'Automated notifications to vendors for compliance lapses.',
      ],
    },
    {
      image: emailfollowup, // Use another image or keep it the same
      heading: 'Automated Update & Follow-Ups',
      list: [
        'Stay informed with real-time updates on status and payment confirmations',
        'Our system initiates reminders and correspondence with vendors to generate invoices',
      ],
    },
  ];

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  // Auto slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="carousel-container">
      <div className="carousel-content" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="carousel-slide" key={index}>
            <img className="gstimage" src={slide.image} alt={slide.heading} />
            <h2 className="carousel-heading">{slide.heading}</h2>
            <ul>
              {slide.list.map((item, i) => (
                <li className="carousel-li" key={i}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="dots">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentSlide === index ? 'active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default CarouselComponent;
