import { useNavigate } from 'react-router-dom';
import './Navbar.scss'
import { API_ENDPOINT_GET_USER_INFO } from 'app/scenes/Auth/auth.constants';
import { apiGet } from 'app/services/apiServices';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { workspaceInfo } from 'app/config/States/users';

const Navbar = () => {
  const navigate = useNavigate();
  const [workspaces, setWorkspaces] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [workspace, setWorkspace] = useRecoilState(workspaceInfo);


  const [selectedWorkspace, setSelectedWorkspace] = useState(
    workspace || null
  );

  

  useEffect(() => {
    if (selectedWorkspace) {
      sessionStorage.setItem("selectedWorkspace", selectedWorkspace);
      setWorkspace(selectedWorkspace)
    }
  }, [selectedWorkspace]);


  useEffect(() => {
    // Fetch workspace data on mount
    const fetchUserInfo = async () => {
      try {
        const response = await apiGet(API_ENDPOINT_GET_USER_INFO, false);
        console.log("API Response:", response);
        if (response.status && response.data?.workspaces) {
          setWorkspaces(response.data.workspaces);
          setFilteredOptions(response.data.workspaces); // Initialize filtered options
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);


  const goBack = () => {
    navigate(-1); // This will navigate the user back to the previous page
  };

      // Filter options based on the search query
      const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredOptions(
          workspaces.filter((workspace) =>
            workspace.name.toLowerCase().includes(query)
          )
        );
      };
    
      // Handle selection of a workspace
      const handleSelectOption = (workspace) => {
        setSelectedWorkspace(workspace.name);
        setIsDropdownOpen(false);
        setSearchQuery("");
        setFilteredOptions(workspaces); // Reset the filtered options
        
              // Dispatch a custom event to notify other pages
        const event = new CustomEvent('workspaceChanged', {
          detail: workspace.name
        });
        window.dispatchEvent(event);
      };

  return (
    <div className="vendorfollowupNav">
  {/* <button className="backButton"   onClick={goBack}>Back</button> */}
  <div>
    {/* <button className="navLink1">Get support</button>
    <button className="navLink2">Demo</button> */}
     <div className="vendorfollowup-dropdown-container">
      <div
        className={`vendorfollowup-dropdown-header ${
          isDropdownOpen ? "active" : ""
        }`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {selectedWorkspace || "Select a workspace"}
        <span className="vendorfollowup-dropdown-icon">
          {isDropdownOpen ? "▲" : "▼"}
        </span>
      </div>

      {isDropdownOpen && (
        <div className="vendorfollowup-dropdown-body">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search workspaces..."
            className="vendorfollowup-dropdown-search"
          />
          {filteredOptions.length > 0 ? (
            filteredOptions.map((workspace) => (
              <div
                key={workspace.id}
                className="vendorfollowup-dropdown-item"
                onClick={() => handleSelectOption(workspace)}
              >
                {workspace.name}
              </div>
            ))
          ) : (
            <div className="vendorfollowup-dropdown-item no-options">
              No workspaces found
            </div>
          )}
        </div>
      )}
    </div>
  </div>
</div>

  )
}

export default Navbar