import { useEffect, useState } from "react";
import { ConfigProvider, Steps } from "antd";
import { Outlet, useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

export default function VendorFollowUp() {
  const navigate = useNavigate();

  return (
    <div>
      <Outlet />
    </div>
  );
}