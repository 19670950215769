import PageHeader from "app/shared/PageHeader";
import "./Hotels.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";

import {
  Button,
  Dropdown,
  Input,
  message,
  Modal,
  Select,
  Tabs,
  Tag,
} from "antd";

import {
  API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST,
  API_ENDPOINT_FETCH_EMAIL_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST,
} from "../Credentials/credentials.constants";
import { apiGet, apiPost } from "app/services/apiServices";

import Loader from "app/shared/Loader";
import HotelRecon from "./components/HotelRecon";
import UnReconciledFlight from "../AppTour/components/OnboardUnReconcilied/UnReconciledFlight";
import TableViewManager from "app/shared/TableViewManager";
import { API_ENDPOINT_RECONCILATION_RAISE_RECON_REQUEST } from "../Reconcilation/reconcilation.constants";
import { useFetcher } from "react-router-dom";
import EmptyState from "app/shared/EmptyState";
import { agTableRowSize } from "app/config/States/agtable";
import { appMetaConfig } from "global.constants";
import { getSubdomain } from "app/utils/generic.helper";
import BulkDownloadWidget from "app/shared/BulkDownloadWidget";
import ReportHistory from "../Flights/components/ReportsHistory";
import { Mixpanel } from "app/config/Mixpanel";
export default function Flights(props: any) {
  const [activeTab, setActiveTab] = useState("2");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const reconPageRef: any = useRef();
  const [showModal, setShowModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [creatingRequest, setCreatingRequest] = useState(false);
  const [showModelFor, setShowModalFor] = useState("");
  const [reportName, setReportName] = useState("");
  const [tableRowSize, setTableRowSize] = useRecoilState<any>(agTableRowSize);
  const domain = getSubdomain();
  useEffect(() => {
    Mixpanel.track("User Landed On Hotel Recon", { Screen: "Hotel Recon" });

    if (
      activeTab === "1" &&
      userDetails?.currentWorkspace?.name !== "MMT Admin"
    ) {
      setActiveTab("2");
    }
  }, [userDetails?.currentWorkspace]);
  const getSceneItems = () => {
    if (userDetails?.currentWorkspace?.name === "MMT Admin") {
      return [
        // { key: "1", label: "Dashboard" },
        {
          key: "2",
          label: "Reconciliation",
        },
      ];
    } else {
      return [
        {
          key: "2",
          label: "Reconciliation",
        },
      ];
    }
  };

  const renderScene = () => {
    switch (activeTab) {
      case "2":
        return (
          <HotelRecon
            ref={reconPageRef}
            moduleId={appMetaConfig[domain]?.hotel_recon || "HOTEL_RECON_MMT"}
          />
        );
      default:
        return <HotelRecon />;
    }
  };

  const handleRaiseReportRequest = async () => {
    setCreatingRequest(true);
    let payload = {
      moduleId: appMetaConfig[domain]?.hotel_recon || "HOTEL_RECON_MMT",
      table_name: "hotel_reocn_mmt",
      name: reportName,
      email: userDetails?.email,
      type: "HOTEL",
    };
    const response = await apiPost(
      API_ENDPOINT_RECONCILATION_RAISE_RECON_REQUEST,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "We will notify you via email once report is ready",
      });
    }
    setCreatingRequest(false);
    setShowModal(false);
    setShowModalFor("");
    setReportName("");
  };
  const handleViewReports = async (report: any) => {
    if (report.key === "1") {
      reconPageRef.current && reconPageRef.current.exportDataToExcel();
      // setShowModalFor("CREATE");
    } else {
      setShowModalFor("HISTORY");
    }
    setShowModal(true);
  };

  console.log("app meta of workspace:", userDetails.currentWorkspace);
  return (
    <div className="Hotels">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <Tabs
              items={getSceneItems()}
              tabBarStyle={{
                borderBottom: "none",
                margin: 0, // Remove the underline border
              }}
              activeKey={activeTab}
              onChange={(tabItem: string) => setActiveTab(tabItem)}
            />
          }
          rightActions={
            activeTab === "2" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TableViewManager
                  moduleId={
                    appMetaConfig[domain]?.hotel_recon || "HOTEL_RECON_MMT"
                  }
                  createView={(viewName: string) =>
                    reconPageRef.current &&
                    reconPageRef.current.createNewView(viewName)
                  }
                  selectView={(viewId: string) =>
                    reconPageRef.current &&
                    reconPageRef.current.loadTableView(viewId)
                  }
                  updateView={(viewId: string) =>
                    reconPageRef.current &&
                    reconPageRef.current.updateTableView(viewId)
                  }
                />
                <div style={{ marginRight: 12, marginLeft: 12 }}>
                  <BulkDownloadWidget
                    getPayload={() =>
                      reconPageRef.current &&
                      reconPageRef.current?.getGridState()
                    }
                    getColumnDefs={() =>
                      reconPageRef.current &&
                      reconPageRef.current?.getAllColumns()
                    }
                    moduleId={
                      appMetaConfig[domain]?.hotel_recon || "HOTEL_RECON_MMT"
                    }
                    tableName={appMetaConfig[domain]?.hotel_recon_table}
                    success={() => setShowModal(false)}
                  />
                </div>

                <Dropdown.Button
                  onClick={() => {
                    setShowModalFor("CREATE");
                    setShowModal(true);
                  }}
                  menu={{
                    items: [
                      { key: "1", label: "Download Current Table" },
                      { key: "2", label: "Reports Export History" },
                    ],
                    onClick: handleViewReports,
                  }}
                  size="small"
                  style={{ marginLeft: 12 }}
                  type="primary"
                >
                  Download Report
                </Dropdown.Button>
              </div>
            ) : null
          }
        />

        <div className="TableContainer">
          {userDetails.approval_status === "ALLOWED" ? (
            renderScene()
          ) : userDetails.currentWorkspace?.approval_status &&
            userDetails.currentWorkspace?.approval_status === "PENDING" ? (
            <EmptyState
              title="Need Approval From Client"
              description="Please check if you have added user from this workspace for acknowledgment"
            />
          ) : (
            renderScene()
          )}
        </div>

        <Modal
          open={showModal}
          footer={false}
          onCancel={() => setShowModal(false)}
          title={
            showModelFor === "CREATE"
              ? "Create Report Request"
              : "Report Download History"
          }
          width={showModelFor === "CREATE" ? 500 : 800}
        >
          {showModelFor === "CREATE" ? (
            <>
              <Input
                placeholder="Report Name"
                onChange={(e: any) => setReportName(e.target.value)}
                value={reportName}
              />
              <div
                style={{
                  marginTop: 12,
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{ marginRight: 12 }}
                  onClick={() => setShowModal(false)}
                  loading={creatingRequest}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleRaiseReportRequest}
                  type="primary"
                  loading={creatingRequest}
                >
                  Create
                </Button>
              </div>
            </>
          ) : (
            <ReportHistory
              moduleId={appMetaConfig[domain]?.hotel_recon || "HOTEL_RECON_MMT"}
            />
          )}
        </Modal>
      </div>
      {contextHolder}
    </div>
  );
}
