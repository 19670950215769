import COLORS from "theme/color";
import { get as getDataWithLodash } from "lodash";
export const workspaceColorMap = [
  "#4555eb",
  "#209f85",
  "#fa694a",
  "#2c3e50",
  "#e74c3c",
];

export const rootHexToRgba = (rootHex: string, opacity: any) => {
  console.log("root hex:", rootHex);
  let hex = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(rootHex);
  hex = hex.replace(/ /g, "");
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  throw new Error("Bad Hex");
};

export const colorPicker = (colorname: string) => {
  return getDataWithLodash(COLORS, colorname, "#000");
};

export const PickWorkspaceColor = (word: any) => {
  if (!word) return "#4555eb";
  const firstChar = word.charAt(0).toLowerCase();
  const alphabetIndex = firstChar.charCodeAt(0) - "a".charCodeAt(0);
  const colorIndex = Math.floor(
    alphabetIndex / (26 / workspaceColorMap.length)
  );
  return workspaceColorMap[colorIndex];
};

export const formatString = (str: string) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};
