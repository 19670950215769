import React, { useState, useEffect, useRef } from "react";
import { Button, Tag, Typography, message, Modal } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined, ArrowUpOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../../AirlinePAN.scss";

export default function AirlinePAN() {
    const navigate = useNavigate();
    const [fileData, setFileData] = useState<{ [key: number]: { file: File | null, url: string | null } }>({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentFile, setCurrentFile] = useState<{ name: string; type: string; url: string } | null>(null);
    const [loading, setLoading] = useState(false);
    const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = e.target.files?.[0];
        if (file) {
            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                message.error("Please upload a valid PDF or image (JPG, JPEG, PNG).");
                return;
            }
            const fileUrl = URL.createObjectURL(file);
            setFileData((prevState) => ({
                ...prevState,
                [index]: { file, url: fileUrl }
            }));

            message.success(`File selected: ${file.name}`);
        }
    };

    const handleUploadClick = (index: number) => {
        fileInputRefs.current[index]?.click();
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleSkip = () => {
        navigate('/credential/airline/question/finkraft/consent/Confirm');
    };

    const handleViewFile = (index: number) => {
        const fileInfo = fileData[index];
        if (fileInfo) {
            setCurrentFile({ name: fileInfo.file?.name || '', type: fileInfo.file?.type || '', url: fileInfo.url || '' });
            setIsModalVisible(true);
        }
    };

    const handleConfirm = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            navigate('/credential/airline/question/finkraft/consent/Confirm');
        }, 2000);
    };

    return (
        <div className="AirlineStart">
            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <span style={{ display: 'inline-flex', alignItems: 'center', marginBottom: 16, cursor: 'pointer' }} onClick={handleBack}>
                    <ArrowLeftOutlined style={{ fontSize: '14px', marginRight: 2, color: '#0A8394', fontWeight: '400' }} />
                    <Typography style={{ marginLeft: '3px', color: '#0A8394', fontFamily: 'Noto Sans' }}>
                        Back
                    </Typography>
                </span>
                <span style={{ display: 'inline-flex', alignItems: 'center', marginBottom: 16, cursor: 'pointer' }} onClick={handleSkip}>
                    <Typography style={{ marginLeft: '3px', color: '#0A8394', fontFamily: 'Noto Sans',fontSize:'14px' }}>
                        skip
                    </Typography>
                    <ArrowRightOutlined style={{ fontSize: '14px', marginLeft: '5px', marginTop: '2px', color: '#0A8394', fontWeight: '400' }} />
                </span>
            </div>
            <Tag color="cyan">GSTIN certificate</Tag>
            <Typography style={{ marginTop: 12, color: "#212121", fontSize: "32px" }}>
                Provide the GSTIN certificate to the listed pan
            </Typography>
            <Typography style={{ marginTop: "18px", color: "#72747D" }}>
                <span style={{ color: "#0A8394", fontSize: "16px" }}>1/5PAN:</span> AA34567807
            </Typography>

            <div className="FormContainer1">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>GSTIN</th>
                            <th>Document/PDF</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(4)].map((_, index) => (
                            <tr key={index}>
                                <td>ABZPB6303M</td>
                                <td> Not Available</td>
                                <td>
                                    {fileData[index]?.file ? (
                                        <Button
                                            type="default"
                                            icon={<EyeOutlined />}
                                            iconPosition="end"
                                            style={{ fontSize: "12px", width: "90%", color: '#0A8394', borderColor: '#0A8394' }}
                                            onClick={() => handleViewFile(index)}
                                        >
                                            View
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            icon={<ArrowUpOutlined />}
                                            iconPosition="end"
                                            style={{ fontSize: "12px", width: "90%" }}
                                            onClick={() => handleUploadClick(index)}
                                        >
                                            Upload
                                        </Button>
                                    )}
                                    <input
                                        type="file"
                                        ref={(el) => (fileInputRefs.current[index] = el)}
                                        style={{ display: "none" }}
                                        accept=".pdf,image/jpeg,image/png,image/jpg" 
                                        onChange={(e) => handleFileSelect(e as unknown as React.ChangeEvent<HTMLInputElement>, index)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Button style={{ width: '100%', fontSize: '14px', height: '43px', marginTop: '15px', color: '#0A8394', border: "1px solid #0A8394" }}>
                Finkraft will download all GST certificates on your behalf.
            </Button>
            <div style={{ display: 'flex', marginTop: '20px', gap: "12px", height: '40px' }}>
                {/* <Button 
                    style={{ fontSize: '14px', color: '#0A8394', border: "1px solid #0A8394", width: '50%', height: "40px", padding: '4px 0px' }} 
                    icon={<ArrowUpOutlined />} 
                >
                    Add in bulk
                </Button> */}
                <Button
                    type="primary"
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    style={{ width: '100%', padding: '4px 0px', height: "40px" }}
                    loading={loading}
                    onClick={handleConfirm}
                >
                    {loading ? 'Loading...' : 'Submit'}
                </Button>

                <Modal
                    visible={isModalVisible}
                    footer={null}
                    onCancel={() => setIsModalVisible(false)}
                    width={800}
                    style={{
                        top: 60,
                    }}
                >
                    {currentFile && currentFile.type === "application/pdf" ? (
                        <iframe
                            src={currentFile.url}
                            style={{ width: "100%", height: "550px", border: "none" }}
                            title={currentFile.name}
                        />
                    ) : (
                        currentFile && (
                            <img
                                src={currentFile.url}
                                alt={currentFile.name}
                                style={{ width: "100%", height: "500px" }}
                            />
                        )
                    )}
                </Modal>
            </div>
        </div>
    );
}
