import { useNavigate, useRoutes } from "react-router-dom";
import RouteMap from "./app/config/routeConfig";
import { getSubdomain } from "app/utils/generic.helper";

// import "theme/index.scss";

const Entry = () => {
  const navigate = useNavigate();
  const domain = getSubdomain();
  const queryParams = new URLSearchParams(window.location.search);
  const tokenParam: any = queryParams.get("token");
  if (domain === "integration") {
    localStorage.clear();
    if (tokenParam) {
      localStorage.setItem("token", tokenParam);
      navigate("/credential/gst/landing");
    }
  }

  const routes = useRoutes([...RouteMap]);
  return routes;
};

export default Entry;
