import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import { useEffect, useRef, useState } from "react";

import Loader from "app/shared/Loader";
import { PlusOutlined, EyeOutlined } from "@ant-design/icons";
import CircleIcon from "@mui/icons-material/Circle";
import { Avatar, Button, Empty, Modal, Spin, Tabs, Tag, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import Typography from "app/shared/Typography";
import "../../Settings.scss";
import PageHeader from "app/shared/PageHeader";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import TableViewSelector from "app/shared/TableViewSelector";
import {
  API_ENDPOINT_SETTING_WORKSPACE_LIST,
  API_ENDPOINT_UPDATE_WORKSPACE_META_EMAIL,
} from "../../settings.constants";
import { colorPicker } from "app/utils/color.helper";
import CreateWorkspace from "../CreateWorkspace";
import color from "theme/color";
import { useNavigate } from "react-router-dom";
import UpdateWorkspace from "../UpdateWorkspace";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
export default function WorkspaceSetting(props: any) {
  const [tableData, setTableData] = useState([]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const handleSelectWorkspace = (workspaceInfo: any) => {
    localStorage.setItem("currentWorkspace", workspaceInfo?.id);
    setUserDetails({ ...userDetails, currentWorkspace: workspaceInfo });

    navigate("/bookings");
  };

  const initialColDefs = [
    {
      field: "name",
      headerName: "Workspace",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: colorPicker("neutral.800") }}
            variant="xs"
            onClick={() => {
              setShowModalFor("UPDATE");
              setShowModal(true);
              setSelectedWorkspace(params?.data);
            }}
          >
            {params.data?.name}
          </Typography>
        );
      },
      valueGetter: (params: any) => params.data?.name,
      filter: "agTextColumnFilter",
    },
    {
      field: "email_address",
      headerName: "Contact Person",
      filter: "agTextColumnFilter",
      editable: true,
    },

    {
      field: "is_active",
      headerName: "Verified",
      enableRowGroup: true,
      minWidth: 200,

      valueGetter: (params: any) => params.data?.approval?.length,
      cellRenderer: (params: any) => {
        return params.data?.approval && params.data?.approval?.length > 0 ? (
          <Tag color="green">Verified</Tag>
        ) : (
          <Tag color="orange">Verification Pending</Tag>
        );
      },
    },
    {
      field: "users",
      headerName: "Users",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return params?.data?.users.length === 0 ? (
          <Tag color="red">Add User</Tag>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.data?.users.map((user: any, key: any) => {
              return <Tag>{user.email}</Tag>;
            })}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    setColumnDefs(initialColDefs);
  }, []);
  useEffect(() => {
    fetchWorkspaceList();
  }, [userDetails.currentWorkspace]);
  const fetchWorkspaceList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_SETTING_WORKSPACE_LIST);
    if (response.status) {
      setTableData(response.data);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleExportTableData = () => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  const handleSuccess = () => {
    setShowModal(false);
    setSelectedWorkspace(null);
    fetchWorkspaceList();
  };

  const handleSearchWorkspace = (query: string) => {
    console.log("values is:L", query);
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  function isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleSaveEmailAddress = async (items: any) => {
    if (isValidEmail(items.email_address)) {
      const response = await apiPost(API_ENDPOINT_UPDATE_WORKSPACE_META_EMAIL, {
        email_address: items.email_address,
        workspaceId: items.id,
      });
      message.success("Email contact is updated");
    } else {
      message.error("Email is invalid");
    }
  };

  return (
    <div className="ScreenContainer">
      <PageHeader
        title="Workspace"
        rightActions={
          <div style={{ display: "flex", alignItems: "center" }}>
            {userDetails?.email.includes("kgrp.in") ||
            userDetails?.email.includes("finkraft.ai") ? (
              <Button
                style={{ marginRight: 12 }}
                type="primary"
                size="small"
                onClick={() => navigate("/settings/workspace-page")} // Use navigate function here
              >
                NewWorkspacePage
              </Button>
            ) : null}
            <div style={{ marginRight: 12 }}>
              <SearchInput
                placeholder="Search worksapces"
                onSearch={handleSearchWorkspace}
              />
            </div>
            <Button
              type="primary"
              size="small"
              // icon={<PlusOutlined />}
              onClick={() => {
                gridRef.current && gridRef.current.exportDataToExcel();
              }}
              // onClick={() => {
              //   setShowModalFor("CREATE");
              //   setShowModal(true);
              // }}
            >
              Export Workspaces
            </Button>
            {/* {userDetails?.email.includes("finkraft.ai") ? ( */}
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowModalFor("CREATE");
                setShowModal(true);
              }}
              style={{ marginLeft: 12 }}
            >
              Add Workspace
            </Button>
            {/* ) : null} */}
          </div>
        }
      />
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={columnDefs}
            groupPanel
            // onRowSaved={handleRowEdit}
            enableDirectEdit
            ref={gridRef}
            footer={false}
            autoResize
            showStatusBar
            onRowSaved={handleSaveEmailAddress}
          />
        )}
      </div>

      <Modal
        title={showModalFor === "UPDATE" ? "Edit Workspace" : "Add Workspace"}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={600}
      >
        {showModalFor === "UPDATE" ? (
          <UpdateWorkspace
            workspace={selectedWorkspace}
            onSuccess={handleSuccess}
          />
        ) : (
          <CreateWorkspace onSuccess={handleSuccess} />
        )}
      </Modal>
      {contextHolder}
    </div>
  );
}
