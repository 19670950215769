import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import Loader from "app/shared/Loader";
import { Empty } from "antd";
import Typography from "app/shared/Typography";
import EmptyState from "app/shared/EmptyState";
import ZAViewSelector from "app/shared/ZAViewSelector";
import { Mixpanel } from "app/config/Mixpanel";
export default function AppDashboard(props: any) {
  const [activeKey, setActiveKey] = useState("");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const [isLoading, setLoading] = useState(false);
  Mixpanel.track("User Landed On Dashboard", { Screen: "Dashboard" });

  useEffect(() => {
    if (userDetails.dashboards && userDetails.dashboards.length > 0) {
      setActiveKey(userDetails.dashboards[0].embedurl);
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [userDetails]);

  const getTabView = () => {
    let tabs: any = [];
    let id = 1;
    userDetails.dashboards.forEach((dashboard: any) => {
      tabs.push({
        title: dashboard.report_name,
        id: id,
        type: "VIEW",
        viewData: dashboard.embedurl,
      });
      id = id + 1;
    });

    return tabs;
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <PageHeader
          title="Dashboard"
          leftActions={
            <ZAViewSelector
              activeKey={activeKey}
              items={userDetails.dashboards || []}
              onViewChange={(data: any, fullObj: any) => setActiveKey(data)}
            />
          }
        />

        <div className="TableContainer">
          {userDetails.dashboards && userDetails.dashboards?.length === 0 ? (
            <EmptyState
              title="No Dashboards Available"
              description={"Try changing workspace for dashboard view"}
            />
          ) : (
            <div
              style={{
                height: "100%",
              }}
            >
              <iframe
                height="100%"
                style={{ width: "100%" }}
                scrolling="no"
                title="Iframe"
                src={activeKey}
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
