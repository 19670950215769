import { initializeApp, getApps } from "firebase/app";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";

import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD33JyUFjugVISjRu_yElDamtvR0-6tBlU",
  authDomain: "prototype-finkraft.firebaseapp.com",
  databaseURL:
    "https://prototype-finkraft-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "prototype-finkraft",
  storageBucket: "prototype-finkraft.appspot.com",
  messagingSenderId: "283994867558",
  appId: "1:283994867558:web:d5038e6f45625e3c86eaa7",
  measurementId: "G-6J2FHW78VS",
};

// Singleton Firebase App
const app = getApps().length ? getApps()[0] : initializeApp(firebaseConfig);
const storage = getStorage(app);
const store = getFirestore(app);

export const uploadFileToFirebase = async (file, path) => {
  if (!file) {
    console.error("No file provided.");
    return;
  }

  console.log("uploading", file.name);

  const storageRef = ref(storage, `${path}/${file.name}`); // Path in Firebase Storage

  // Start the upload
  const uploadTask = uploadBytesResumable(storageRef, file);

  return true;
};

export const uploadJsonToFirestore = async (workspaceId, jsonData) => {
  try {
    if (!workspaceId) {
      throw new Error("Workspace ID is required but not provided.");
    }

    if (!jsonData || !Array.isArray(jsonData)) {
      throw new Error("Invalid JSON data provided.");
    }

    // Ensure data contains required fields
    jsonData = jsonData.map((item) => {
      // Trim whitespace and check for required fields
      return {
        gstin: item.gstin?.trim() || "",
        username: item.username?.trim() || "",
        password: item.password?.trim() || "",
      };
    });

    // Check for missing fields
    const missingFields = jsonData.filter(
      (item) => !item.gstin || !item.username || !item.password
    );
    if (missingFields.length > 0) {
      throw new Error(
        "Some entries are missing required fields (gstin, username, password)."
      );
    }

    const docRef = doc(store, "workspace_credential", workspaceId); // Replace "workspace_credential" with your collection name

    // Fetch existing data
    const docSnapshot = await getDoc(docRef);
    let existingData = [];
    if (docSnapshot.exists()) {
      existingData = docSnapshot.data().data || [];
    }

    // Log existing data
    console.log("Existing data:", existingData);

    // Remove duplicates by GSTIN
    const existingGstins = new Set(existingData.map((item) => item.gstin));
    const newData = jsonData.filter((item) => !existingGstins.has(item.gstin));

    // Merge new data with existing data
    const mergedData = [...existingData, ...newData];

    // Upload merged data to Firestore
    await setDoc(docRef, { data: mergedData }, { merge: true });

    console.log("JSON data uploaded successfully to Firestore.");
    return mergedData;
  } catch (error) {
    console.error("Error uploading JSON data to Firestore:", error);
    throw error;
  }
};
