import PageHeader from "app/shared/PageHeader";
import "./IntegrationNew.scss";
import Typography from "app/shared/Typography";
import { Button, Checkbox, Divider, Input, message,Modal } from "antd";
import SearchInput from "app/shared/SearchInput";
import TextArea from "antd/es/input/TextArea";
import {
  PhoneOutlined,
  ArrowRightOutlined,
  SendOutlined,
  CheckOutlined,
  CheckCircleFilled,
  ClockCircleFilled,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import { useEffect, useState } from "react";
import {
  API_ENDPOINT_AIRLINE_CREDENTIAL_LIST_PANS,
  API_ENDPOINT_AIRLINE_CREDENTIAL_LIST_VENDORS,
} from "../AirlineCredentialFlow/airlinecredentialflow.constants";
import { apiGet, apiPost } from "app/services/apiServices";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_CREDENTIAL_ADD_NEW } from "./integrationnew.constants";
import Loader from "app/shared/Loader";
import airlineLogos from "app/utils/IconMaps";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST } from "../Credentials/credentials.constants";
import checks from '../AirlineCredentialFlow/components/AirlineConfirmPage/checks.svg';
import select from '../AirlineCredentialFlow/components/AirlineConfirmPage/select.svg';

const airlineCredMap: any = {
  "Lufthansa Airline": "lufthansa_swiss",
  "Air India": "airindia",
  "Air Canada": "aircanada",
  "Air France": "airfrance",
  "Klm Royal Dutch": "klm",
  "Air Asia" : 'airasia',
  "Air Mauritius":"air_mauritius",
  "Aeroflot Airlines":"aeroflot_airlines"
};
export default function IntegrationNew(props: any) {
  const [selectedAirline, setSelectedAirline] = useState("");
  const [selectedPAN, setSelectedPAN] = useState(0);
  const [airlineList, setAirlineList] = useState<any[]>([]);
  const [panList, setPanList] = useState<any[]>([]);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [filteredAirlines, setFilteredAirlines] = useState<any[]>([]);
  const [credentialMap, setCredentialMap] = useState<any>(null);
  const [filteredPANS, setFilteredPANS] = useState<any[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [integrating, setIntegrating] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
  const [isCustomSelected, setIsCustomSelected] = useState(false); 

  useEffect(() => {
    fetchAirlineList();
    // fetchPANList();
    handleCredentialMap();
  }, [userDetails.currentWorkspace]);
  const fetchAirlineList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_AIRLINE_CREDENTIAL_LIST_VENDORS);
    if (response.status) {
      setAirlineList(response.data);
      setSelectedAirline(response.data[0]?.airline_name);
      setFilteredAirlines(response.data);
    }
    setLoading(false);

    // fetchPANList();
  };

  const handleCredentialMap = async () => {
    const response = await apiGet(API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST);
    console.log("Fetched Credentials:", response.data); // Debug
    if (response.status) {
      setCredentialMap(response.data);
    } else {
    }
  };

  const handleAddCredential = async () => {
    setIntegrating(true);
    if (!username || !password) {
      messageApi.error({
        type: "error",
        content: "Please add your credential",
      });
    } else {
      const payload = {
        airline_name: airlineCredMap[selectedAirline],
        workspace_id: userDetails?.currentWorkspace.id,
        portal_id: username,
        portal_pass: password,
        code: code,
        pan: selectedPAN,
      };
       
      console.log("Payload being sent:", payload);
      const response = await apiPost(API_ENDPOINT_CREDENTIAL_ADD_NEW, payload);
      if (response.status) {
        messageApi.open({
          type: "success",
          content: "Credential added succesfully",
        });
        // setUsername("");
        // setPassword("");
        // setCode("");
      } else {
        messageApi.error({
          type: "error",
          content: "Please add your credential",
        });
      }
    }
    setIntegrating(false);
  };

  const handleAirlineSearch = (query: string) => {
    if (!query) {
      setFilteredAirlines(airlineList);
      return;
    }
    setFilteredAirlines(
      airlineList.filter((item: any) =>
        item.airline_name.toLowerCase().includes(query)
      )
    );
  };

  const handlePanSearch = (query: string) => {
    if (!query) {
      setFilteredPANS(panList);
      return;
    }
    setFilteredPANS(
      panList.filter((item: any) => item.pan.toLowerCase().includes(query))
    );
  };

  

  const handleSelectAirline = (airlineInfo: any) => {
    console.log(
      "credentialmap:",
      credentialMap.credentials,
      airlineInfo.airline_name
    );
    setSelectedAirline(airlineInfo.airline_name);
    let findPANS: any = [];
    if (airlineCredMap[airlineInfo.airline_name]) {
      (credentialMap.credentials || []).forEach((element: any) => {
        console.log("creds Object", element);

        if (element.airline_name === airlineCredMap[airlineInfo.airline_name]) {
          findPANS.push(element);
        }
      });
    }
    console.log("Filtered PANs:", findPANS);
    findPANS.forEach((pan: any) => {  // **New: Added this loop to log credentials for each PAN**
      console.log("PAN:", pan.pan);
      console.log("Credentials for PAN:", {
        portalId: pan.portal_id,
        portalPass: pan.portal_pass,
        code: pan.code,
      });
    });
    setPanList(findPANS);
    setFilteredPANS(findPANS);
    let initialPan = findPANS.length > 0 ? findPANS[0] : {};
    setSelectedPAN(initialPan.pan || "");
    setUsername(initialPan.portal_id);
    setPassword(initialPan.portal_pass);
    setCode(initialPan.code);
  };

  const showModal = () => setIsModalVisible(true);
  
    const handleModalClose = () => setIsModalVisible(false);
  
    const issues = [
      "Issue_01_regarding some common thing",
      "Issue_02_regarding GST cred",
      "Issue_03_regarding Airline",
      "Issue_04_regarding sign up",
      "Issue_05_regarding some common thing",
      "Custom"
    ];

    const handleCheckboxChange = (issue: string) => {
      if (issue === "Custom") {
        setIsCustomSelected(!isCustomSelected);
        if (!isCustomSelected) {
          setSelectedIssues([issue]); 
        } else {
          setSelectedIssues([]); 
        }
      } else {
        setSelectedIssues((prev) =>
          prev.includes(issue)
            ? prev.filter((item) => item !== issue)
            : [...prev, issue]
        );
      }
    };

    const dropdownContent = (
      <div
        style={{
          border: "1px solid ##E8EAF1",
          borderRadius: "4px",
          marginTop: "8px",
          padding: "16px 16px",
          backgroundColor: "#F8F9FB",
          display: "flex",
          gap: "16px",
          flexDirection: "column",
        }}
      >
        {issues.map((issue, index) => (
          issue === "Custom" || !isCustomSelected ? ( // Show all options except "Custom" if not selected
            <div style={{ display: "flex", gap: "15px" }} key={index}>
              <Checkbox
                checked={selectedIssues.includes(issue)}
                onChange={() => handleCheckboxChange(issue)}
              />
              <Typography>{issue}</Typography>
            </div>
          ) : null
        ))}
      </div>
    );

    const handlePanSelection = (panInfo: any) => {
      const selectedPanInfo = filteredPANS.find(
        (pan: any) => pan.pan === panInfo.pan
      );
    
      if (selectedPanInfo) {
        // **Update the credentials for the selected PAN**
        setUsername(selectedPanInfo.portal_id);
        setPassword(selectedPanInfo.portal_pass);
        setCode(selectedPanInfo.code);
      }
    };

  return (
    <div className="IntegrationNew">
      <div className="ScreenContainer">
        <div className="Header">
          <PageHeader title="Integration Airline" />
        </div>
        <div className="MainContainer">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="AirlineListContainer">
                <div className="ArilineListHeader">
                  <Typography>Total Airline : {airlineList.length}</Typography>
                  <Divider style={{ margin: 16 }} />
                </div>
                <div className="AirlineListContent">
                  <Typography style={{ marginBottom: 12 }}>
                    List of Airlines
                  </Typography>
                  <SearchInput
                    placeholder="Search Airline"
                    colored
                    size="medium"
                    onSearch={handleAirlineSearch}
                  />
                  <div className="ItemList">
                    {filteredAirlines.map((airline: any, key: any) => {
                      return (
                        <div
                          key={key}
                          className={
                            "Item " +
                            (selectedAirline === airline.airline_name
                              ? " active"
                              : "")
                          }
                          onClick={() => handleSelectAirline(airline)}
                        >
                          {/* <Checkbox
                            checked={selectedAirline === airline.airline_name}
                          /> */}
                          <img
                            src={airlineLogos[airline.airline_name]}
                            alt="AirlineIcon"
                            style={{ width: 34, height: 34, marginLeft: 12 }}
                          />
                          <Typography style={{ marginLeft: 8 }}>
                            {airline.airline_name}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="AirlineListContainer">
                <div className="ArilineListHeader">
                  <Typography>
                    Total PAN registered : {panList.length}
                  </Typography>
                  <Divider style={{ margin: 16, width: "100%" }} />
                </div>
                <div className="AirlineListContent">
                  <Typography style={{ marginBottom: 12 }}>
                    List of PANs
                  </Typography>
                  <SearchInput
                    placeholder="Search PAN"
                    colored
                    size="medium"
                    onSearch={handlePanSearch}
                  />
                  <div className="ItemList">
                    {filteredPANS.map((panInfo: any, key: any) => {
                      return (
                        <div
                          className={
                            "Item " +
                            (selectedPAN === panInfo.pan ? " active" : "")
                          }
                          key={key}
                          onClick={() => {
                            setSelectedPAN(panInfo.pan);  // Update selected PAN
                            handlePanSelection(panInfo);  // Call the function to update credentials
                          }}
                        >
                          {/* <Checkbox checked={selectedPAN === panInfo.pan} /> */}
                          {panInfo.portal_id ? (
                            <CheckCircleFilled
                              style={{ color: colorPicker("primary.700") }}
                            />
                          ) : (
                            <ClockCircleFilled style={{ color: "#e67e22" }} />
                          )}

                          <Typography
                            variant="caption"
                            style={{
                              marginLeft: 8,
                              color: colorPicker("neutral.700"),
                            }}
                          >
                            {panInfo.pan}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="CredentialFormContainer">
                <div className="AirlineInfoContainer">
                  <div className="HeaderContainerInfo">
                    <img
                      src={airlineLogos[selectedAirline]}
                      alt="TailIcon"
                      className="AirlineLogo"
                    />
                    <Typography variant="h4">{selectedAirline}</Typography>
                  </div>

                  <Button icon={<PhoneOutlined />} onClick={showModal}>Get Help</Button>
                </div>
                <div
                  style={{
                    marginTop: 8,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ color: colorPicker("primary.700") }}>
                    Airlines:{" "}
                    <span style={{ color: colorPicker("neutral.700") }}>
                      {selectedAirline}
                    </span>
                  </Typography>
                  <Typography
                    style={{
                      color: colorPicker("primary.700"),
                      marginLeft: 24,
                    }}
                  >
                    PAN:{" "}
                    <span style={{ color: colorPicker("neutral.700") }}>
                      {selectedPAN}
                    </span>
                  </Typography>
                </div>

                <div className="FormContainer">
                  <div className="FormGroup">
                    <Typography
                      variant="xs"
                      style={{
                        color: colorPicker("neutral.700"),
                        marginBottom: 3,
                      }}
                    >
                      Username
                    </Typography>
                    <Input
                      placeholder="Username"
                      onChange={(e: any) => setUsername(e.target.value)}
                      value={username}
                    />
                  </div>

                  <div className="FormGroup">
                    <Typography
                      variant="xs"
                      style={{
                        color: colorPicker("neutral.700"),
                        marginBottom: 3,
                      }}
                    >
                      Password
                    </Typography>
                    <Input
                      placeholder="Password"
                      type="password"
                      onChange={(e: any) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>

                  <div className="FormGroup">
                    <Typography
                      variant="xs"
                      style={{
                        color: colorPicker("neutral.700"),
                        marginBottom: 3,
                      }}
                    >
                      Code
                    </Typography>
                    <Input
                      placeholder="Code"
                      onChange={(e: any) => setCode(e.target.value)}
                      value={code}
                    />
                  </div>
                  <div className="FormGroup">
                    <Button
                      icon={<ArrowRightOutlined />}
                      style={{ width: "100%" }}
                      type="primary"
                      size="large"
                      onClick={handleAddCredential}
                      loading={integrating}
                    >
                      Submit
                    </Button>
                  </div>

                  <Divider plain>Don't have credentials?</Divider>

                  <div
                    className="FormGroup"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      icon={<SendOutlined />}
                      style={{ width: "49%" }}
                      onClick={() =>
                        navigate("/credential/airline/question/invite/user")
                      }
                    >
                      Invite user through mail
                    </Button>
                    <Button
                      icon={<CheckOutlined />}
                      style={{ width: "49%" }}
                      onClick={() =>
                        navigate(
                          "/credential/airline/question/finkraft/consent"
                        )
                      }
                    >
                      Allow Finkraft to do the needful
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {contextHolder}
      <Modal
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          title=""
        >
          <span style={{ display: "flex", gap: "10px" }}>
            <QuestionCircleOutlined style={{ color: "#0A8394", fontSize: "24px" }} />
            <Typography
              style={{
                color: "#0A8394",
                fontSize: "24px",
                fontWeight: "400",
                fontFamily: "Noto Sans",
              }}
            >
              Help center
            </Typography>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Typography style={{ color: "#212121" }}>Choose the issue here</Typography>
            <img
              src={select}
              onClick={() => setDropdownVisible(!dropdownVisible)}
              style={{ cursor: "pointer" }}
            />
          </div>
          {dropdownVisible && dropdownContent}
  
          {/* Conditionally render the text field when "Custom" is selected */}
          {isCustomSelected && (
            <div style={{ marginTop: "10px" }}>
              <TextArea
                placeholder="Custom issue description"
                style={{ width: "100%" }}
              />
            </div>
          )}
  
          <Button
            type="primary"
            style={{
              width: "100%",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "24px",
              fontSize: "16px",
            }}
          >
            <img
              src={checks}
              style={{ width: "26px", height: "26px", marginRight: "8px" }}
            />
            Submit
          </Button>
        </Modal>
    </div>
  );
}
