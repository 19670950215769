import { getSubdomain } from "app/utils/generic.helper";
import mixpanel from "mixpanel-browser";
import * as amplitude from "@amplitude/analytics-browser";

mixpanel.init("addefa73708ddbfb54f37105531bd376");
// amplitude.init("138fa76f87337742e910255e44c58cf1", { autocapture: false });

let env_check = process.env.NODE_ENV !== "production";
console.log("env_check", env_check);

let actions = {
  identify: (id) => {
    if (env_check) {
      mixpanel.identify(id);
      // amplitude.setUserId(id);
    }
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) {
      mixpanel.track(name, { ...props, Platform: getSubdomain() });
      // amplitude.track(name, { ...props, Platform: getSubdomain() });
    }
  },
  people: {
    set: (props) => {
      if (env_check) {
        mixpanel.people.set(props);
        // amplitude.setUserId(props.email);
        // amplitude.Identify({
        //   name: props.name,
        //   email: props.email,
        // });
        // amplitude.set({
        //   name: props.name,
        //   email: props.email,
        // });
      }
    },
  },
};

export let Mixpanel = actions;
