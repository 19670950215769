import { Outlet } from "react-router";
import AppLogo from "static/images/FinLogo.png";
import "./AirlineCredentialFlow.scss";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Avatar, message, Checkbox, Modal, Button } from "antd";
import { QuestionCircleOutlined, } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import DoubleQuotes from "static/images/DoubleQuotes.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AirOnboarIllustration1 from "static/images/illustration/AirOnboardIllustration1.svg";
import AirOnboarIllustration2 from "static/images/illustration/AirOnboardIllustration2.svg";
import AirOnboarIllustration3 from "static/images/illustration/AirOnboardIllustration3.svg";
import { Widgets } from "@mui/icons-material";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_AIRLINE_CREDENTIAL_LIST_VENDORS } from "./airlinecredentialflow.constants";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_GET_USER_INFO } from "../Auth/auth.constants";
import { useNavigate } from "react-router-dom";
import Loader from "app/shared/Loader";
import ISOCert from "static/images/ISO.svg";
import GDPRCert from "static/images/GDPR.svg";
import SOCCert from "static/images/SOC.svg";
import select from './components/AirlineConfirmPage/select.svg';
import checks from './components/AirlineConfirmPage/checks.svg'
import Logo from 'static/images/Finkr@ftLogo.png'

export default function AirlineCredentialFlow(props: any) {
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
  const [isCustomSelected, setIsCustomSelected] = useState(false); // State for "Custom" option


  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      let mmtWorkspace = (response.data.workspaces || []).find(
        (item: any) => item.name === "MMT Admin"
      );
      console.log("mmt workspace", mmtWorkspace);
      localStorage.setItem(
        "currentBoxId",
        response.data.workspaces?.[0]?.box_folder_id
      );
      localStorage.setItem(
        "currentBoxId",
        response.data.workspaces?.[0]?.box_folder_id
      );

      setUserDetails({
        ...response.data,
        currentWorkspace: mmtWorkspace
          ? mmtWorkspace
          : response.data.workspaces[0],
        clusterId: response.data.cluster_id,
        currentBoxId: response.data.workspaces?.[0]?.box_folder_id,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const showModal = () => setIsModalVisible(true);

  const handleModalClose = () => setIsModalVisible(false);

  const issues = [
    "Issue_01_regarding some common thing",
    "Issue_02_regarding GST cred",
    "Issue_03_regarding Airline",
    "Issue_04_regarding sign up",
    "Issue_05_regarding some common thing",
    "Custom"
  ];
  const handleCheckboxChange = (issue: string) => {
    if (issue === "Custom") {
      setIsCustomSelected(!isCustomSelected);
      if (!isCustomSelected) {
        setSelectedIssues([issue]);
      } else {
        setSelectedIssues([]);
      }
    } else {
      setSelectedIssues((prev) =>
        prev.includes(issue)
          ? prev.filter((item) => item !== issue)
          : [...prev, issue]
      );
    }
  };

  const dropdownContent = (
    <div
      style={{
        border: "1px solid ##E8EAF1",
        borderRadius: "4px",
        marginTop: "8px",
        padding: "16px 16px",
        backgroundColor: "#F8F9FB",
        display: "flex",
        gap: "16px",
        flexDirection: "column",
      }}
    >
      {issues.map((issue, index) => (
        issue === "Custom" || !isCustomSelected ? ( // Show all options except "Custom" if not selected
          <div style={{ display: "flex", gap: "15px" }} key={index}>
            <Checkbox
              checked={selectedIssues.includes(issue)}
              onChange={() => handleCheckboxChange(issue)}
            />
            <Typography>{issue}</Typography>
          </div>
        ) : null
      ))}
    </div>
  );

  return isLoading ? (
    <div
      style={{
        height: "100vh",
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loader />
    </div>
  ) : (

    <div className="AirlineCredentialFlow" >
      <div className="Container">
        <div className="LeftContainer">
          <div className="TopLogo">
            <img alt="AppLogo" src={Logo} />
            {/* <div className="MiniDivder" /> */}
          </div>

          <div className="CarouselContainer">
            <Carousel showArrows={false} showThumbs={false} showStatus={false}>
              <div>
                <img
                  src={AirOnboarIllustration1}
                  style={{ width: 288, height: 288 }}
                />
                <div
                  className="MetaInfo"
                  style={{ marginTop: 64, marginBottom: 66 }}
                >
                  <Typography
                    style={{
                      fontSize: 40,
                      color: colorPicker("neutral.950"),
                      lineHeight: "44px",
                      marginBottom: 24,
                    }}
                  >
                    Instant Invoice Access
                  </Typography>

                  <Typography
                    style={{
                      color: colorPicker("neutral.800"),
                      marginTop: 6,
                      textAlign: "center",
                      width: 500,
                    }}
                  >
                    Effortlessly retrieve your invoices with our seamless,
                    hassle-free system—quick, easy, and designed for your
                    convenience!
                  </Typography>
                </div>
              </div>
              <div>
                <img
                  src={AirOnboarIllustration2}
                  style={{ width: 288, height: 288 }}
                />
                <div
                  className="MetaInfo"
                  style={{ marginTop: 64, marginBottom: 48 }}
                >
                  <Typography
                    style={{
                      fontSize: 40,
                      color: colorPicker("neutral.950"),
                      lineHeight: "44px",
                      marginBottom: 24,
                    }}
                  >
                    Instant Invoice Access
                  </Typography>

                  <Typography
                    style={{
                      color: colorPicker("neutral.800"),
                      marginTop: 6,
                      textAlign: "center",
                      width: 500,
                    }}
                  >
                    Effortlessly retrieve your invoices with our seamless,
                    hassle-free system—quick, easy, and designed for your
                    convenience!
                  </Typography>
                </div>
              </div>
              <div>
                <img
                  src={AirOnboarIllustration3}
                  style={{ width: 288, height: 288 }}
                />
                <div
                  className="MetaInfo"
                  style={{ marginTop: 64, marginBottom: 48 }}
                >
                  <Typography
                    style={{
                      fontSize: 40,
                      color: colorPicker("neutral.950"),
                      lineHeight: "44px",
                      marginBottom: 24,
                    }}
                  >
                    Instant Invoice Access
                  </Typography>

                  <Typography
                    style={{
                      color: colorPicker("neutral.800"),
                      marginTop: 6,
                      textAlign: "center",
                      width: 500,
                    }}
                  >
                    Effortlessly retrieve your invoices with our seamless,
                    hassle-free system—quick, easy, and designed for your
                    convenience!
                  </Typography>
                </div>
              </div>
            </Carousel>
          </div>

          <div className="CertificateContainer">
            <img src={ISOCert} alt="ISO Image" />
            <img
              src={GDPRCert}
              alt="ISO Image"
              style={{ marginLeft: 48, marginRight: 48 }}
            />
            <img src={SOCCert} alt="ISO Image" />
          </div>
        </div>
        <div className="RightContainer">
          <Typography
            style={{
              marginBottom: 24,
              color: colorPicker("neutral.700"),
              textAlign: "right",
            }}
          >
            Having trouble?{" "}
            <a style={{ color: colorPicker("primary.700"), cursor: "pointer" }} onClick={showModal}>
              Get help
            </a>
          </Typography>
          <Outlet />
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        title=""
      >
        <span style={{ display: "flex", gap: "10px" }}>
          <QuestionCircleOutlined style={{ color: "#0A8394", fontSize: "24px" }} />
          <Typography
            style={{
              color: "#0A8394",
              fontSize: "24px",
              fontWeight: "400",
              fontFamily: "Noto Sans",
            }}
          >
            Help center
          </Typography>
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography style={{ color: "#212121" }}>Choose the issue here</Typography>
          <img
            src={select}
            onClick={() => setDropdownVisible(!dropdownVisible)}
            style={{ cursor: "pointer" }}
          />
        </div>
        {dropdownVisible && dropdownContent}

        {/* Conditionally render the text field when "Custom" is selected */}
        {isCustomSelected && (
          <div style={{ marginTop: "10px" }}>
            <TextArea
              placeholder="Custom issue description"
              style={{ width: "100%" }}
            />
          </div>
        )}

        <Button
          type="primary"
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "24px",
            fontSize: "16px",
          }}
        >
          <img
            src={checks}
            style={{ width: "26px", height: "26px", marginRight: "8px" }}
          />
          Submit
        </Button>
      </Modal>
    </div>
  );
}
