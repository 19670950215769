import React, { useEffect, useState } from "react";

import { message, theme } from "antd";
import { Outlet, useNavigate } from "react-router";
import "./MasterLayout.scss";

import PrimaryNav from "../PrimaryNav";
import { useRecoilState } from "recoil";
import { uploadInfo, userInfo } from "app/config/States/users";
import {
  API_ENDPOINT_GET_GST_STATUS,
  API_ENDPOINT_GET_TABLE_SCHEMA,
  API_ENDPOINT_GET_USER_INFO,
} from "app/scenes/Auth/auth.constants";
import { apiGet } from "app/services/apiServices";
import Loader from "../Loader";
import AppLoader from "../AppLoader";
import { tableSchema } from "app/config/States/agtable";
import GlobalUploader from "../GlobalUploader";
import { Mixpanel } from "app/config/Mixpanel";

const MasterLayout = (props: any) => {
  const [expandedLayout, setExpandedLayout] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [tableSchemaInfo, setTableSchema] = useRecoilState<any>(tableSchema);
  const [uploaderInfo, setUploadInfo] = useRecoilState<any>(uploadInfo);

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const fetchAllTableSchema = async () => {
    const response = await apiGet(API_ENDPOINT_GET_TABLE_SCHEMA);
    if (response.status) {
      setTableSchema(response.data);
    }
  };

  useEffect(() => {
    fetchUserInfo();
    fetchAllTableSchema();
  }, []);

  const fetchWorkspaceGstinStatus = async () => {
    const response = await apiGet(API_ENDPOINT_GET_GST_STATUS);
    if (response.status) {
      return response.data;
    }
    return null;
  };
  const fetchUserInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      if (!response.data) {
        localStorage.clear();
        navigate("/auth/signin");
      }
      let mmtWorkspace = (response.data.workspaces || []).find(
        (item: any) =>
          item.name === "MMT Admin" || item.name.toLowerCase().includes("admin")
      );
      const userWorkspaceGstinStatus = await fetchWorkspaceGstinStatus();

      setUserDetails({
        ...response.data,
        currentWorkspace: mmtWorkspace
          ? mmtWorkspace
          : response.data.workspaces[0],
        clusterId: response.data.cluster_id,
        gstinStatus: userWorkspaceGstinStatus,
      });

      localStorage.setItem(
        "currentWorkspace",
        mmtWorkspace ? mmtWorkspace.id : response.data.workspaces[0]?.id
      );

      Mixpanel.identify(response.data.id);
      Mixpanel.track("Successful login", {
        domain: response?.data?.email?.split("@")[1],
      });
      //@ts-ignore
      localStorage.setItem("session_start_time", new Date().getTime());
      Mixpanel.track("Session Start", {
        login_time: new Date().toISOString(), // Optional: track the login timestamp
      });
      Mixpanel.people.set({
        $name: response.data.name,
        $email: response.data.email,
        email: response.data.email,
        name: response.data.name,
      });

      // if (!response.data.is_admin) {
      //   navigate("/flights");
      // }
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  console.log("userDetails", userDetails);
  return isLoading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <AppLoader />
    </div>
  ) : (
    <div className="MasterLayout">
      <div className="NavigationContainer">
        <PrimaryNav openMenu={() => setExpandedLayout(!expandedLayout)} />
      </div>
      {/* {userDetails.hasOnboared ? (
        <div
          className="PendingTaskContainer"
          style={{ display: expandedLayout ? "flex" : "none" }}
        >
          <OnboardingTasks />
        </div>
      ) : null} */}
      <div
        className={
          "ContentLayout maxWidthLG " +
          (expandedLayout ? " " : " CollapsedLayout")
        }
      >
        <div className="AppContent">
          <Outlet />
        </div>
      </div>
      <GlobalUploader />

      {contextHolder}
    </div>
  );
};

export default MasterLayout;
