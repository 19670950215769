import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import './Tables.css';
import { Empty } from 'antd';

const GSTR31FilingStatusSummary = ({ Avgdelaydata }) => {
  // Extract and format GSTR3B data
  const gstr3bData = Avgdelaydata["GSTR3B_avg_delay"] || {};
  const rowData = Object.entries(gstr3bData).map(([year, delay]) => ({
    year: year,
    delay: delay
  }));

  const columnDefs = [
    { headerName: 'Year of Return Per', field: 'year', resizable: true, sortable: true, flex: 1.1 },
    { headerName: 'Avg Delay in Day(s)', field: 'delay', resizable: true, sortable: true, cellClass: params => params.value < 0 ? 'negative-delay' : '', flex: 1 },
  ];

  return (
    <div className="gstr31-table-container">
      <h2 style={{ color: 'hsla(187, 87%, 31%, 1)' }}>GSTR1 Filing Status Summary</h2>
      <div className="header-line"></div>
      {rowData && rowData.length > 0 ? (
        <div className="ag-theme-alpine" style={{ height: 200, width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            domLayout="autoHeight"
            headerHeight={50}
            rowHeight={30}
          />
        </div>
      ) : (
        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '68px' }}>
          <Empty description="No GSTR1 Summary Available" />
        </div>
      )}
    </div>
  );
};

export default GSTR31FilingStatusSummary;
