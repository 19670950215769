import checkimg from '../../../../assets/images/checkimg.png'
import "./EmailConfirmation.scss";


const EmailConfirmationModel = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="email-confirmation-modal">
        <div className="modal-header">
          <h2>Mail sent</h2>
          <button className="email-modal-close" onClick={onClose}>
    ×
  </button>
        </div>
        <div className="modal-body">
        <div className="confirmation-icon">
        <img src={checkimg}/>
      </div>
          <p>Email Invitation Sent successfully for GST integration</p>
        </div>
        <div className="modal-footer">
          <button className="continue-button" onClick={onClose}>
            Continue →
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmationModel;
