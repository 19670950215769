import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Select,
  Tag,
  Modal
} from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  ArrowRightOutlined,
  FileOutlined,
  SendOutlined,
  ArrowLeftOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import "../../AirlineCredentialFlow.scss";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_AIRLINE_CREDENTIAL_WORKSPACE_CONSENT } from "../../airlinecredentialflow.constants";
import DoneImage from "static/images/doneIllustration.svg";
import { useNavigate } from "react-router-dom";
type FieldType = {
  email?: string;
  password?: string;
};

export default function AirlineFinkraftConsent(props: any) {
  const [gotConsent, setGotConsent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [messageApi, contextHolder] = message.useMessage();
  const [consentDone, setConsentDone] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const handleProvideConsent = async () => {
  //   setLoading(true);
  //   if (!gotConsent) {
  //     messageApi.error({
  //       type: "error",
  //       content: "Please give us the consent for the same",
  //     });
  //     return;
  //   }

  //   const response = await apiPost(
  //     API_ENDPOINT_AIRLINE_CREDENTIAL_WORKSPACE_CONSENT
  //   );
  //   if (response.status) {
  //     setConsentDone(true);
  //     messageApi.success({
  //       type: "success",
  //       content: "Thanks for providing your consent",
  //     });
  //   }
  //   setLoading(false);
  // };

  const handleBack = () => {
    navigate(-1);
  };

  const handleAirlinePAN = () => {
    if (!gotConsent) {
      setIsModalVisible(true);
      return;
    }
    navigate("/credential/airline/question/finkraft/consent/PAN");
  };

  const handleOk = () => {
    setIsModalVisible(false); 
  };

  return (
    <div className="AirlineStart">
      {/* {!consentDone ? ( */}
      <>
        <span style={{ display: 'inline-flex', alignItems: 'center', marginBottom: 16, cursor: 'pointer' }} onClick={handleBack}>
          <ArrowLeftOutlined style={{ fontSize: '14px', marginRight: 2, color: '#0A8394', fontWeight: '400' }} />
          <Typography style={{ marginLeft: '3px', color: '#0A8394', fontFamily: 'Noto Sans', }}>
            Back
          </Typography>
        </span>
        <br />
        <Tag color="cyan">Airline Integration</Tag>

        <Typography
          variant="h5"
          style={{ color: colorPicker("neutral.900"), marginTop: 24 }}
        >
          Allow finkraft.ai to do the needful
        </Typography>

        <div
          style={{ display: "flex", alignItems: "flex-start", marginTop: 12 }}
        >
          <Checkbox
            style={{ marginRight: 12 }}
            onChange={() => setGotConsent(!gotConsent)}
          />
          <Typography style={{ color: colorPicker("neutral.800") }}>
            I give consent to finkraft do the needful for getting invoices
            from airlines on behalf of
            <b> {userDetails?.currentWorkspace?.name}</b>
          </Typography>
        </div>

        <Button
          style={{ width: "100%", marginTop: 42 }}
          type="primary"
          icon={<ArrowRightOutlined />}
          size="large"
          //onClick={handleProvideConsent}
          onClick={handleAirlinePAN}
          loading={isLoading}
        >
          Start submitting
        </Button>
        <Modal
          title={
            <span style={{ color: '#0A8394', fontWeight: 'bold' }}>
              <WarningOutlined style={{ marginRight: 10, fontSize: 20, color: '#0A8394' }} />
              Action Required
            </span>
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleOk}
          footer={[
            <Button
              key="back"
              onClick={handleOk}
              style={{
                backgroundColor: '#0A8394',
                color: 'white',
                border: 'none',
              }}
            >
              Got it!
            </Button>,
          ]}
          centered
          bodyStyle={{
            textAlign: 'center',
            fontSize: 16,
            color: '#555',
            padding: '20px',
            height: '70px'
          }}
          width={500}
        >
          <Typography style={{ fontSize: 16, fontWeight: '500', color: colorPicker("neutral.800") }}>
            Please tick the checkbox to give your consent before proceeding. This step is required to continue.
          </Typography>
        </Modal>
      </>


      {/* ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img src={DoneImage} alt="successIcon" style={{ width: 74 }} />
          <Typography variant="h5" style={{ marginTop: 12 }}>
            Thanks for your consent
          </Typography>

          <Button style={{ marginTop: 24 }} onClick={() => navigate("/")}>
            Go to Home
          </Button>
        </div>
      )}
      {contextHolder} */}
    </div>
  );
}
