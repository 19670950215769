export const API_ENDPOINT_AUTH_SIGNIN = `${process.env.REACT_APP_API_URL}/auth/login`;
export const API_ENDPOINT_GET_USER_INFO = `${process.env.REACT_APP_API_URL}/meta/user/info`;
export const API_ENDPOINT_AUTH_SIGNIN_OTP = `${process.env.REACT_APP_API_URL}/auth/loginotp`;
export const API_ENDPOINT_AUTH_SIGNIN_VERIFY_OTP = `${process.env.REACT_APP_API_URL}/auth/loginotpverify`;
export const API_ENDPOINT_AUTH_OAUTH_VERIFY_CODE = `${process.env.REACT_APP_API_URL}/auth/email/verify/`;
export const API_ENDPOINT_AUTH_SIGNUP = `${process.env.REACT_APP_API_URL}/auth/register`;
export const API_ENDPOINT_AUTH_EMAIL_RESEND = `${process.env.REACT_APP_API_URL}/auth/email/resend`;
export const API_ENDPOINT_GET_TABLE_SCHEMA = `${process.env.REACT_APP_API_URL}/agtable/schemas/list`;
export const API_ENDPOINT_GET_GST_STATUS = `${process.env.REACT_APP_API_URL}/meta/workspace/gstin/status`;
export const API_ENDPOINT_GET_WORKSPACE_ONBOARDING_STATUS = `${process.env.REACT_APP_API_URL}/meta/workspace/onboarding/status`;
