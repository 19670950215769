import { Button, Checkbox, Divider, Form, Input, message, Tag } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useState } from "react";
import AppLogo from "static/images/Logo.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import "../../GSTCredentialFlow.scss";
import { useNavigate} from "react-router-dom";

type FieldType = {
  email?: string;
  password?: string;
};

export default function GSTAddAnotherPan(props: any) {
  const [pan, setPan] = useState("");
  const navigate = useNavigate();
 

  return (
    <div className="AirlineStart">
      <Tag color="cyan">Onboarding</Tag>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        Let’s add another PAN/GSTIN 
      </Typography>

      <Typography style={{ marginTop: 12 }}>
        Add PAN/GSTIN of other entity 
      </Typography>

      <div style={{ marginTop: 12 }}>
        <Typography
          variant="xs"
          style={{ marginBottom: 4, color: colorPicker("neutral.700") }}
        >
          PAN/GSTIN
        </Typography>
        <Input
          placeholder="Enter PAN/GSTIN here"
          onChange={(e: any) => setPan(e.target.value)}
        />
      </div>

      <Button
        style={{ width: "100%", marginTop: 42 }}
        type="primary"
        icon={<ArrowRightOutlined />}
        iconPosition="end"
        onClick={() => navigate("/credential/gst/entity_view2", { state: pan })}
      >
        Submit
      </Button>
    </div>
  );
}