import { ConfigProvider } from "antd";
import Entry from "./Entry";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import "theme/index.scss";
import "./RootApp.scss";
import { IntlProvider } from "react-intl";
import { RecoilRoot } from "recoil";
import { useEffect } from "react";
import { getSubdomain } from "app/utils/generic.helper";
import { appMetaConfig } from "global.constants";
import { Mixpanel } from "app/config/Mixpanel";
// import RecoilizeDebugger from "recoilize";
function App() {
  const defaultData = {
    colorPrimary: "#0A8394", //2575dcc
  };

  useEffect(() => {
    const domain = getSubdomain();
    document.title = appMetaConfig[domain || "app"]?.title || "Finkraft"; // Set the document title
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      const sessionStartTime: any = localStorage.getItem("session_start_time"); // Store on Session Start
      const sessionEndTime = new Date().getTime();
      const sessionDuration = (sessionEndTime - sessionStartTime) / 1000; // in seconds
      if (sessionStartTime) {
        Mixpanel.track("Session Duration", {
          session_duration: sessionDuration,
        });
      }
    };

    // Add the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <IntlProvider locale="en-IN" defaultLocale="en-IN">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: defaultData.colorPrimary,
          },
        }}
      >
        <RecoilRoot>
          {/* <RecoilizeDebugger /> */}

          <Router>
            <Entry />
          </Router>
        </RecoilRoot>
      </ConfigProvider>
    </IntlProvider>
  );
}

export default App;
