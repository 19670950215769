import React, { useState, useRef } from "react";
import { Button, Tag, Typography, message, Modal } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined, ArrowUpOutlined,EyeOutlined  } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../../AirlinePAN.scss";

export default function AirlinePAN() {
    const navigate = useNavigate();
    const [fileData, setFileData] = useState<{ [key: number]: { file: File | null, url: string | null } }>({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentFile, setCurrentFile] = useState<{ name: string; type: string; url: string } | null>(null);
    const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = e.target.files?.[0];
        if (file) {
            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                message.error("Please upload a valid PDF or image (JPG, JPEG, PNG).");
                return;
            }
            const fileUrl = URL.createObjectURL(file);
            setFileData((prevState) => ({
                ...prevState,
                [index]: { file, url: fileUrl }
            }));

            message.success(`File selected: ${file.name}`);
        }
    };

    const handleUploadClick = (index: number) => {
        fileInputRefs.current[index]?.click();
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleSubmit = () => {
        navigate("/credential/airline/question/finkraft/consent/GSTIN");
    };

    const handleViewFile = (index: number) => {
        const fileInfo = fileData[index];
        if (fileInfo) {
            setCurrentFile({ name: fileInfo.file?.name || '', type: fileInfo.file?.type || '', url: fileInfo.url || '' });
            setIsModalVisible(true);
        }
    };

    return (
        <div className="AirlineStart">
            <span
                style={{ display: "inline-flex", alignItems: "center", marginBottom: 16, cursor: "pointer" }}
                onClick={handleBack}
            >
                <ArrowLeftOutlined
                    style={{ fontSize: "14px", marginRight: 2, color: "#0A8394", fontWeight: "400" }}
                />
                <Typography style={{ marginLeft: "3px", color: "#0A8394", fontFamily: "Noto Sans" }}>
                    Back
                </Typography>
            </span>
            <br/>
            <Tag color="cyan">PAN soft copy</Tag>
            <Typography style={{ marginTop: 12, color: "#212121", fontSize: "32px" }}>
                Provide the PAN to the below list
            </Typography>
            <Typography style={{ marginTop: "18px", color: "#72747D" }}>
                <span style={{ color: "#0A8394", fontSize: "16px" }}>Entity:</span> Alam Mubashshir
            </Typography>

            <div className="FormContainer1" >
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>PAN's</th>
                            <th>Document/PDF</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(5)].map((_, index) => (
                            <tr key={index}>
                                <td>ABZPB6303M</td>
                                <td>Not Available</td>
                                <td>
                                    {fileData[index]?.file ? (
                                        <Button
                                            type="default"
                                            icon={<EyeOutlined />}
                                            iconPosition="end"
                                            style={{ fontSize: "12px", width: "90%",color:'#0A8394',borderColor:'#0A8394' }}
                                            onClick={() => handleViewFile(index)}
                                        >
                                            View
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            icon={<ArrowUpOutlined />}
                                            iconPosition="end"
                                            style={{ fontSize: "12px", width: "90%" }}
                                            onClick={() => handleUploadClick(index)}
                                        >
                                            Upload
                                        </Button>
                                    )}
                                    <input
                                        type="file"
                                        ref={(el) => (fileInputRefs.current[index] = el)}
                                        style={{ display: "none" }}
                                        accept=".pdf,image/jpeg,image/png,image/jpg" 
                                        onChange={(e) => handleFileSelect(e as unknown as React.ChangeEvent<HTMLInputElement>, index)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Button
                type="primary"
                style={{ width: "100%", fontSize: "14px", height: "40px", marginTop: "15px" }}
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                onClick={handleSubmit}
            >
                Submit
            </Button>
            <Modal
                visible={isModalVisible}
                //title={currentFile?.name}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
                width={800}
                style={{
                    top: 60, 
                }}
              >
                {currentFile && currentFile.type === "application/pdf" ? (
                    <iframe
                        src={currentFile.url}
                        style={{ width: "100%", height: "550px", border: "none" }}
                        title={currentFile.name}
                    />
                ) : (
                    currentFile && (
                        <img
                            src={currentFile.url}
                            alt={currentFile.name}
                            style={{ width: "100%", height: "500px" }}
                        />
                    )
                )}
            </Modal>
        </div>
    );
}

