import { useState } from 'react';
import img from '../../../../assets/images/inviteimg.png';
import EmailModal from '../EmailModel/EmailModel';
import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar';
import "./InvitePage.scss";

const InvitePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
    {/* <Navbar/> */}
    <Header/>
    <div className="invitepage-container">
      <div className="invitepage-left">
       <div className="invitepage-content">
       <div className="invitepage-image">
          <img
            src={img}
            alt="Invite Tax People"
          />
        </div>
        <h1 className="invitepage-title">Invite Tax People</h1>
        <p className="invitepage-description">
          Please invite someone from your tax team to provide us with the details of the GST integration.
        </p>
       </div>
      </div>

      <div className="invitepage-right">
        <div className="invitepage-form-container">
          <div className="invitepage-header">
            <span className="invitepage-tag">GST Integration</span>
            <h2 className="invitepage-form-title">Invite Tax People</h2>
            <h3 className="invitepage-form-subtitle">
              Who can help us with <strong>GST integration</strong>
            </h3>
          </div>
          <div className="invitepage-form">
            <div className="invitepage-input-group">
              <label htmlFor="username">User name</label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Enter name here"
              />
            </div>
            <div className="invitepage-input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter mail here"
              />
            </div>
            <div className="invitepage-input-group">
              <label htmlFor="contact">Contact number</label>
              <input
                type="text"
                id="contact"
                name="contact"
                placeholder="Enter number here"
              />
            </div>
            <button   onClick={openModal} className="invitepage-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M8.3334 11.6667L17.5001 2.5M8.3334 11.6667L11.2501 17.5C11.2866 17.5798 11.3453 17.6474 11.4192 17.6948C11.493 17.7422 11.579 17.7674 11.6667 17.7674C11.7545 17.7674 11.8404 17.7422 11.9143 17.6948C11.9881 17.6474 12.0468 17.5798 12.0834 17.5L17.5001 2.5M8.3334 11.6667L2.50006 8.75C2.42027 8.71344 2.35266 8.65474 2.30526 8.58088C2.25786 8.50701 2.23267 8.4211 2.23267 8.33333C2.23267 8.24557 2.25786 8.15965 2.30526 8.08579C2.35266 8.01193 2.42027 7.95323 2.50006 7.91667L17.5001 2.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg><span>Send Invite</span>
            </button>
          </div>

        </div>
      </div>
      
    </div>
    
    <EmailModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default InvitePage;
