export const processZARowForColumnDefs = (
  columnList: any,
  useTrim = false,
  emptyDestination = false
) => {
  let rowDataArray: any = [];

  let KeyValueData = Object.entries(columnList[0] || {});

  KeyValueData.forEach((item) => {
    if (typeof item[1] === "object") return;
    rowDataArray.push({
      id: item[0],
      source_key: item[0],
      destination_key: emptyDestination
        ? ""
        : useTrim
        ? item[0].replace(/[\s_\/\\-]/g, "")
        : item[0],
      data_type: (typeof item[1]).toUpperCase(),
      header_name: item[0],

      ui_format_type:
        (typeof item[1]).toUpperCase() === "NUMBER" ? "NUMBER" : "FALSE",

      enable_aggregation:
        (typeof item[1]).toUpperCase() === "NUMBER" ? true : false,
      enable_row_group: true,
      enable_column_pivot: false,
      filter_type: (typeof item[1]).toUpperCase(),
      hide_column: false,
      default_grouping: false,
      default_pinning: "FALSE",
      column_group_show: false,
      default_sorting: "FALSE",
      column_grouping: "",
      match_config_json: "{}",
      validation_type: "",
      is_required: true,
    });
  });
  return rowDataArray;
};

export const processTableRowForColumnDef = (columnList: any) => {
  let rowDataArray: any = [];

  let KeyValueData = Object.entries(columnList[0] || {});

  KeyValueData.forEach((item) => {
    if (typeof item[1] === "object") return;
    rowDataArray.push({
      id: item[0],
      source_key: item[0],
      destination_key: item[0],
      data_type: (typeof item[1]).toUpperCase(),
      header_name: item[0],
      ui_format_type: "FALSE",
      enable_aggregation:
        (typeof item[1]).toUpperCase() === "NUMBER" ? true : false,
      enable_row_group: true,
      enable_column_pivot: false,
      filter_type: (typeof item[1]).toUpperCase(),
      hide_column: false,
      default_grouping: false,
      default_pinning: "FALSE",
      column_group_show: false,
      default_sorting: "FALSE",
      column_grouping: "",
      match_config_json: "{}",
    });
  });
  return rowDataArray;
};

const fitlerType: any = {
  STRING: "agTextColumnFilter",
  NUMBER: "agNumberColumnFiltr",
};
const colTDataType: any = {
  NUMBER: "numericColumn",
};
export const getColumnDefinationFromTable = (columnList: any) => {
  let rowDataArray: any = [];

  let KeyValueData = Object.entries(columnList[0] || {});

  KeyValueData.forEach((item) => {
    if (typeof item[1] === "object") return;
    rowDataArray.push({
      field: item[0],
      headerName: item[0],
      formatType: (typeof item[1]).toUpperCase() === "NUMBER" ? "NUMBER" : "",
      data_type: (typeof item[1]).toUpperCase(),
      enable_aggregation:
        (typeof item[1]).toUpperCase() === "NUMBER" ? true : false,
      enable_row_group: true,
      enable_column_pivot: false,
      filter: fitlerType[(typeof item[1]).toUpperCase()],
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      // type: colTDataType[(typeof item[1]).toUpperCase()],
    });
  });
  console.log("finalcoldefs:", rowDataArray);
  return rowDataArray;
};
