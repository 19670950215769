import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Select,
  Tag,
} from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  ArrowRightOutlined,
  DownCircleOutlined,
  SendOutlined,
  CloudUploadOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import "../../GSTCredentialFlow.scss";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_AIRLINE_CREDENTIAL_LIST_ALL_GSTIN } from "app/scenes/AirlineCredentialFlow/airlinecredentialflow.constants";
import { stateCodeMap } from "app/shared/AgTable/stateIconMap";
import SearchDropdown from "app/shared/WorkspaceSelector";
import { Mixpanel } from "app/config/Mixpanel";

type FieldType = {
  email?: string;
  password?: string;
};

export default function GSTAddSingle(props: any) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [gstin, setGSTIN] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [allGstin, setAllGstin] = useState<any[]>([]);

  useEffect(() => {
    fetchAllGstin();
  }, [userDetails.currentWorkspace]);
  const handleSubmitCredential = () => {
    const payload = {
      username: username,
      password: password,
      gstin: gstin,
    };

    console.log("payload is :", payload);
    navigate("/credential/gst/add/review", { state: payload });
  };

  const fetchAllGstin = async () => {
    const response = await apiGet(
      API_ENDPOINT_AIRLINE_CREDENTIAL_LIST_ALL_GSTIN +
        userDetails?.currentWorkspace?.id
    );
    if (response.status) {
      setAllGstin(response.data);
      setGSTIN(response?.data?.[0]?.gstin || "");
    }
  };
  const handleBack = () => {
    navigate(-1);
  };

  console.log("current workspace:", userDetails);
  const handleSelectWorkspce = (workspaceInfo: any) => {
    let newWorksapceData = { ...userDetails, currentWorkspace: workspaceInfo };
    setUserDetails(JSON.parse(JSON.stringify(newWorksapceData)));
    localStorage.setItem("currentWorkspace", workspaceInfo.id);
    Mixpanel.track("User Changed Workspace", {
      Workspace: workspaceInfo?.name,
    });
  };

  return (
    <div className="AirlineStart">
      <span
        style={{
          display: "inline-flex",
          alignItems: "center",
          marginBottom: 16,
          cursor: "pointer",
        }}
        onClick={handleBack}
      >
        <ArrowLeftOutlined
          style={{
            fontSize: "14px",
            marginRight: 2,
            color: "#0A8394",
            fontWeight: "400",
          }}
        />
        <Typography
          style={{
            marginLeft: "3px",
            color: "#0A8394",
            fontFamily: "Noto Sans",
          }}
        >
          Back
        </Typography>
      </span>
      <br />
      <Tag color="cyan">One step ahead</Tag>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        GST INTEGRATION
      </Typography>

      <Typography style={{ marginTop: 12 }} variant="h6">
        Enter GST Portal Integration for
      </Typography>

      <div className="EnitiyInfoContainer">
        <div className="EntityNameContainer">
          <SearchDropdown
            workspaces={userDetails?.workspaces}
            handleSelectWorkspce={handleSelectWorkspce}
          />
        </div>
        <div className="EntityNameContainer">
          <Select
            placeholder="Borderless"
            variant="borderless"
            value={gstin}
            onChange={(item: any) => setGSTIN(item)}
            options={allGstin.map((item: any) => {
              return {
                value: item.gstin,
                label: `${item.gstin} [${
                  stateCodeMap[item.gstin.substring(0, 2)]
                }]`,
              };
            })}
          />
        </div>
      </div>
      <div className="FormContainer">
        <div className="FormGroup">
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.700"), marginBottom: 3 }}
          >
            User Id
          </Typography>
          <Input
            placeholder="User ID"
            onChange={(event: any) => setUsername(event.target.value)}
          />
        </div>
        <div className="FormGroup">
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.700"), marginBottom: 3 }}
          >
            Password
          </Typography>
          <Input
            placeholder="Password"
            onChange={(event: any) => setPassword(event.target.value)}
          />
        </div>
      </div>

      <Button
        style={{ width: "100%", marginTop: 42 }}
        type="primary"
        icon={<ArrowRightOutlined />}
        onClick={handleSubmitCredential}
      >
        Submit
      </Button>

      <Button
        style={{ width: "100%", marginTop: 42 }}
        icon={<CloudUploadOutlined />}
        onClick={() => navigate("/credential/gst/add/review", { state: null })}
      >
        Upload Bulk Credential
      </Button>

      <Button
        style={{ width: "100%", marginTop: 42 }}
        icon={<SendOutlined />}
        onClick={() => navigate("/credential/gst/invite/user")}
      >
        Invite user who has credential
      </Button>
    </div>
  );
}
