import { Button, Checkbox, Divider, Form, Input, message, Tag } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useState } from "react";
import AppLogo from "static/images/Logo.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import "../../GSTCredentialFlow.scss";
import { useNavigate } from "react-router-dom";

type FieldType = {
  email?: string;
  password?: string;
};

export default function GSTStart(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="AirlineStart">
      <Tag color="cyan">Integration</Tag>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        GST INTEGRATION
      </Typography>

      <Typography style={{ marginTop: 12 }} variant="h6">
        To find out how much your entities can save.
      </Typography>

      <Typography
        style={{ marginTop: 12, color: colorPicker("neutral.700") }}
        variant="caption"
      >
        We require you to integrate with GST Portal Credentials.We will match
        your datasets with GSTR 2B and generate a report for the claimable
        amount.{" "}
      </Typography>

      <Button
        style={{ width: "100%", marginTop: 42 }}
        type="primary"
        icon={<ArrowRightOutlined />}
        onClick={() => navigate("/credential/gst/add/single")}
      >
        Okay,let's proceed
      </Button>

      <Button
        style={{ width: "100%", marginTop: 42 }}
        icon={<ArrowRightOutlined />}
        onClick={() => navigate("/credential/gst/invite/user")}
      >
        I didn't understand, let me invite my tax team member
      </Button>
    </div>
  );
}
