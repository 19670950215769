import { Button, Checkbox, Divider, Form, Input, message, Tag } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useState } from "react";
import AppLogo from "static/images/Logo.svg";
import { colorPicker } from "app/utils/color.helper";
import "../../GSTCredentialFlow.scss";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined, FileOutlined } from "@ant-design/icons";

type FieldType = {
  email?: string;
  password?: string;
};

export default function GSTEntityView(props: any) {
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  const [selectedEntity, setSelectedEntity] = useState("ABCA992212A");
  const navigate = useNavigate();

  return (
    <div className="AirlineStart">
      <Tag color="cyan">Integration</Tag>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        2 entities added, Are you associated with more entities?
      </Typography>

      <div className="EntityBoxContainer">
        <div
          className="EntityBox"
          style={{
            border: selectedEntity
              ? `2px solid ${colorPicker("primary.700")}`
              : "1px solid #dfe0e7",
          }}
        >
          <Typography
            style={{ color: colorPicker("primary.700") }}
            variant="caption"
          >
            Schneider INDIA PRIVATE LIMTED
          </Typography>
          <div className="EntityBoxFooter">
            <Typography style={{}} variant="xs">
              PAN{" "}
              <b style={{ color: colorPicker("primary.700") }}>ABCA992212A</b>
            </Typography>
            <Typography style={{}} variant="xs">
              GSTINs <b style={{ color: colorPicker("primary.700") }}>12</b>
            </Typography>
          </div>
        </div>

        <div className="EntityBox">
          <Typography
            style={{ color: colorPicker("primary.700") }}
            variant="caption"
          >
            Schneider INDIA PRIVATE LIMTED
          </Typography>
          <div className="EntityBoxFooter">
            <Typography style={{}} variant="xs">
              PAN{" "}
              <b style={{ color: colorPicker("primary.700") }}>ABCA992212A</b>
            </Typography>
            <Typography style={{}} variant="xs">
              GSTINs <b style={{ color: colorPicker("primary.700") }}>12</b>
            </Typography>
          </div>
        </div>
      </div>

      <div className="QuestionareContainer" style={{ marginTop: 48 }}>
        <div
          className={`QuestionBox ${
            selectedQuestion === 1 ? "--selected" : ""
          }`}
          onClick={() => {
            setSelectedQuestion(1);
            navigate("/integrationnew");
          }}
        >
          <div className="LeftQuestion">
            <div className="IconBox">
              <FileOutlined style={{ color: colorPicker("primary.700") }} />
            </div>
            <div className="QuestionMeta">
              <Typography
                variant="h6"
                style={{ color: colorPicker("primary.800") }}
                weight={500}
              >
                Yes, lets add another PAN/GSTIN
              </Typography>
              <Typography
                variant="xs"
                style={{ color: colorPicker("primary.700") }}
              >
                I have the necessary credentials to access the system and ensure
                compliance with all requirements.
              </Typography>
            </div>
          </div>
          <div className="RightQuestion">
            <ArrowRightOutlined style={{ color: colorPicker("primary.700") }} />
          </div>
        </div>
        <div
          className={`QuestionBox ${
            selectedQuestion === 2 ? "--selected" : ""
          }`}
          onClick={() => {
            setSelectedQuestion(2);
            navigate("/credential/airline/question/invite");
          }}
        >
          <div className="LeftQuestion">
            <div className="IconBox">
              <FileOutlined style={{ color: colorPicker("primary.700") }} />
            </div>
            <div className="QuestionMeta">
              <Typography
                variant="h6"
                style={{
                  color: colorPicker(
                    selectedQuestion === 2 ? "primary.800" : "neutral.800"
                  ),
                }}
                weight={500}
              >
                Let’s find how much I can save first
              </Typography>
              <Typography
                variant="xs"
                style={{
                  color: colorPicker(
                    selectedQuestion === 2 ? "primary.800" : "neutral.700"
                  ),
                }}
              >
                My access is restricted due to missing credentials. Could you
                provide help to proceed further.
              </Typography>
            </div>
          </div>
          <div className="RightQuestion">
            <ArrowRightOutlined style={{ color: colorPicker("primary.700") }} />
          </div>
        </div>
      </div>

      <Button
        style={{ width: "100%", marginTop: 42 }}
        type="primary"
        icon={<ArrowRightOutlined />}
        onClick={() => navigate("/credential/airline/question")}
      >
        Continue
      </Button>
    </div>
  );
}
