import ProtectedRoute from "app/utils/ProtectedRoute";
import { GSTCredentialFlow } from ".";
import GSTStart from "./components/GSTStart";
import GSTEntityView from "./components/GSTEntityView";
import GSTAddSingle from "./components/GSTAddSingle";
import GSTAddReview from "./components/GSTAddReview";
import GSTAddReviewFinal from "./components/GSTAddReviewFinal";
import GSTAddDone from "./components/GSTAddDone";
import GSTEntityView2 from "./components/GSTEntityView2";
import GstCredLanding from "./components/GstCredLanding";
import GSTInviteTax from "./components/GSTInviteTax";
import GSTAddPan from "./components/GSTAddPan";
import GSTAddAnotherPan from "./components/GSTAddAnotherPan";

//eslint-disable-next-line
export default [
  {
    path: "/credential/gst",
    element: (
      <ProtectedRoute>
        <GSTCredentialFlow />
      </ProtectedRoute>
    ),
    strict: true,
    children: [
      {
        path: "/credential/gst/start",
        element: <GSTStart />,
        exact: true,
      },
      {
        path: "/credential/gst/entity_view",
        element: <GSTEntityView />,
        exact: true,
      },
      {
        path: "/credential/gst/add/single",
        element: <GSTAddSingle />,
        exact: true,
      },
      {
        path: "/credential/gst/invite/user",
        element: <GSTInviteTax />,
        exact: true,
      },
      {
        path: "/credential/gst/add/pan",
        element: <GSTAddPan />,
      },
      {
        path: "/credential/gst/add/anotherpan",
        element: <GSTAddAnotherPan />,
      },
    ],
  },
  {
    path: "/credential/gst/add/review",
    element: <GSTAddReview />,
  },
  {
    path: "/credential/gst/add/review/final",
    element: <GSTAddReviewFinal />,
  },
  {
    path: "/credential/gst/add/done",
    element: <GSTAddDone />,
  },

  {
    path: "/credential/gst/entity_view2",
    element: <GSTEntityView2 />,
  },
  {
    path: "/credential/gst/landing",
    element: <GstCredLanding />,
    exact: true,
  },
];
