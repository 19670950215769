import { handleCellRenderer as clientCellRenderer } from "app/shared/AgTable/AgUtility";
import { handleCellRenderer } from "app/shared/AgTable/AgServerUtility";

const openSidebarRenderer = (params: any) => {
  params.api.openToolPanel("pdfRenderer", { paramData: params });
};
export const getProcessedDefinition = (
  columnArray: any,
  type = "SERVER",
  pdfFunction: any
) => {
  // Helper function to add cellRendererParams
  const addCellRendererParams = (columnData: any) => {
    if (columnData.formatType === "INVOICE") {
      return {
        ...columnData,
        cellRendererParams: () => ({
          label: "View Invoice",
          handleClick: (params: any) => {
            pdfFunction(params.data?.[columnData.field]);
            openSidebarRenderer(params);
          },
        }),
      };
    }
    return columnData;
  };

  // Use map to return a new array with modified children and added cellRendererParams
  return columnArray
    .map((columnData: any) => {
      // Skip columns with hide: true
      if (columnData.hide) {
        return null;
      }

      // Clone the columnData to avoid mutating the original object
      columnData = addCellRendererParams({ ...columnData });

      if (columnData.children && columnData.children.length > 0) {
        // Clone and update children by mapping over them and adding cellRendererParams if needed
        columnData = {
          ...columnData,
          children: columnData.children
            .map((element: any) => {
              // Skip children with hide: true
              if (element.hide) {
                return null;
              }

              element = addCellRendererParams({ ...element }); // Apply cellRendererParams to children as well
              return {
                ...element,
                ...(type === "SERVER"
                  ? handleCellRenderer()
                  : clientCellRenderer()),
                excelStyle: {
                  id: "headerStyle1",
                  font: {
                    bold: true,
                    color: { rgb: "FF0000" }, // Red color
                  },
                  alignment: {
                    horizontal: "Center",
                    vertical: "Center",
                  },
                  fill: {
                    fgColor: { rgb: "D3D3D3" }, // Gray background color
                  },
                },
              };
            })
            .filter((child: any) => child !== null), // Filter out null children
        };
      }
      return {
        ...columnData,
        excelStyle: {
          id: "headerStyle1",
          font: {
            bold: true,
            color: { rgb: "FF0000" }, // Red color
          },
          alignment: {
            horizontal: "Center",
            vertical: "Center",
          },
          fill: {
            fgColor: { rgb: "D3D3D3" }, // Gray background color
          },
        },
      }; // Return the updated columnData
    })
    .filter((columnData: any) => columnData !== null); // Filter out null columns
};

export const getSubdomain = () => {
  const { hostname } = window.location; // Get the hostname (e.g., sub.domain.com)
  const parts = hostname.split("."); // Split the hostname into parts (e.g., ['sub', 'domain', 'com'])

  if (parts.length > 2) {
    // If there are more than two parts, the first part is the subdomain
    return parts[0];
  } else {
    // No subdomain exists
    return "app";
  }
};

export const downloadSampelCrendentialCSV = async () => {
  let csvString = `gstin,username,password
                    09AABCG3241G1ZL,testuser1,user_password
                    09AABCG3141G1ZL,testuser2,user_password
                    09AABCG8111G1ZL,testuser3,user_password`;
  try {
    // Convert the CSV string to a Blob
    const csvBlob = new Blob([csvString], { type: "text/csv" });

    // Convert the Blob object to a data URL
    const csvDataUrl = URL.createObjectURL(csvBlob);

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = csvDataUrl;
    link.setAttribute("download", "sample_credentials.csv");

    // Trigger the download by programmatically clicking the anchor element
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading CSV file:", error);
  }
};
