import { Button, Checkbox, Divider, Form, message, Tag } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useState } from "react";
import AppLogo from "static/images/Logo.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import "../../AirlineCredentialFlow.scss";
import { useNavigate } from "react-router-dom";

type FieldType = {
  email?: string;
  password?: string;
};

export default function AirlineStart(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="AirlineStart">
      <Tag color="cyan">Integration</Tag>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        Airline Credentials
      </Typography>

      <Typography
        variant="caption"
        style={{ marginTop: 12, color: colorPicker("neutral.700") }}
      >
        Discover the oasis of reconciliation, where efficiency and precision
        converge in perfect equilibrium.
      </Typography>

      <Button
        style={{ width: "100%", marginTop: 42 }}
        type="primary"
        icon={<ArrowRightOutlined />}
        onClick={() => navigate("/credential/airline/question")}
      >
        Continue
      </Button>
    </div>
  );
}
