import Typography from "app/shared/Typography";
import "./GstCredLanding.scss";
import {
  colorPicker,
  formatString,
  PickWorkspaceColor,
  workspaceColorMap,
} from "app/utils/color.helper";
import { Carousel } from "react-responsive-carousel";
import AirOnboarIllustration1 from "static/images/illustration/AirOnboardIllustration1.svg";
import AirOnboarIllustration2 from "static/images/illustration/AirOnboardIllustration2.svg";
import AirOnboarIllustration3 from "static/images/illustration/AirOnboardIllustration3.svg";
import ISOCert from "static/images/ISO.svg";
import GDPRCert from "static/images/GDPR.svg";
import SOCCert from "static/images/SOC.svg";
import { App, Avatar, Button, Checkbox, Modal, Tag, Upload } from "antd";

// @ts-ignore
import DatamapsIndia from "react-datamaps-india";
import AppLogo from "static/images/FinLogo.png";
import { AgTableClient } from "app/shared/AgTable";
import { STATE_GSTIN_ICON } from "app/shared/AgTable/stateIconMap";
import Uploader from "app/shared/Uploader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FileOutlined } from "@ant-design/icons";
import PieChartIllustration from "static/images/PieChart.svg";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import {
  API_ENDPOINT_GET_USER_INFO,
  API_ENDPOINT_GET_WORKSPACE_ONBOARDING_STATUS,
} from "app/scenes/Auth/auth.constants";
import { apiGet } from "app/services/apiServices";
import Loader from "app/shared/Loader";
import Login from "app/scenes/Auth/components/Login";
import AutoLogin from "app/scenes/Auth/components/AutoLogin";

export default function GstCredLanding(props: any) {
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  const [selectedEntity, setSelectedEntity] = useState("ABCA992212A");
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [onboardingMeta, setOnboardingMeta] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchUserInfo();
    const token = localStorage.getItem("token");
    console.log("token:", token);
  }, []);

  const fetchWorkspaceOnboardingMeta = async () => {
    const response = await apiGet(
      API_ENDPOINT_GET_WORKSPACE_ONBOARDING_STATUS +
        "/" +
        userDetails?.currentWorkspace?.id
    );
    setOnboardingMeta(response.data);
  };

  useEffect(() => {
    if (!userDetails?.currentWorkspace?.id) return;
    fetchWorkspaceOnboardingMeta();
  }, [userDetails?.currentWorkspace?.id]);

  const fetchUserInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      let mmtWorkspace = (response.data.workspaces || []).find(
        (item: any) => item.name === "MMT Admin"
      );

      localStorage.setItem(
        "currentBoxId",
        response.data.workspaces?.[0]?.box_folder_id
      );
      localStorage.setItem(
        "currentBoxId",
        response.data.workspaces?.[0]?.box_folder_id
      );

      setUserDetails({
        ...response.data,
        currentWorkspace: mmtWorkspace
          ? mmtWorkspace
          : response.data.workspaces[0],
        clusterId: response.data.cluster_id,
        currentBoxId: response.data.workspaces?.[0]?.box_folder_id,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  console.log("userDetails", userDetails);
  return isLoading ? (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        width: "100wh",
      }}
    >
      <Loader />
    </div>
  ) : (
    <div className="GstCredLanding">
      <div className="Header">
        <img src={AppLogo} alt="HeaderLogo" />
      </div>
      <div className="MainContainer">
        <div className="EntityMetaContainer">
          <div className="EntitiyContainerLeft">
            {/* <img
              src={userDetails.currentWorkspace?.meta?.logo || AppLogo}
              style={{ width: 200 }}
              alt="ClientLogo"
            /> */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: 44 }}>
                <Avatar
                  size={38}
                  style={{
                    backgroundColor: PickWorkspaceColor(
                      userDetails?.currentWorkspace?.name
                    ),
                    color: workspaceColorMap[1],
                    cursor: "pointer",
                    borderRadius: 8,
                    marginRight: 6,
                  }}
                >
                  <Typography style={{ color: "white" }} variant="h5">
                    {`${userDetails?.currentWorkspace?.name?.[0]}`}
                  </Typography>
                </Avatar>
              </div>

              <Typography variant="h5">
                {formatString(userDetails?.currentWorkspace?.name)}
              </Typography>
            </div>
            {/* <div className="ClientInfoContainer">
              <Typography style={{ color: colorPicker("neutral.700") }}>
                {userDetails?.currentWorkspace?.name}
              </Typography>
            </div> */}

            <Typography
              style={{ marginTop: 24, color: colorPicker("primary.700") }}
            >
              You might <b>STILL</b> be loosing millions on flight and hotel
              bookings. Let’s find out how much you can save.
            </Typography>

            <div className="TimerContainer">
              <Typography style={{ color: colorPicker("neutral.800") }}>
                The deadline for claiming GST of 2024-2025 is fast approaching!
              </Typography>
              <div className="TimeCountDownContainer">
                <div className="TimeBox">
                  <Typography variant="caption">45</Typography>
                  <Typography
                    variant="xs"
                    style={{ color: colorPicker("neutral.700") }}
                  >
                    Days
                  </Typography>
                </div>
                <div className="TimeBox">
                  <Typography variant="caption">12</Typography>
                  <Typography
                    variant="xs"
                    style={{ color: colorPicker("neutral.700") }}
                  >
                    Hour
                  </Typography>
                </div>
                <div className="TimeBox">
                  <Typography variant="caption">12</Typography>
                  <Typography
                    variant="xs"
                    style={{ color: colorPicker("neutral.700") }}
                  >
                    Min
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="EntitiyContainerRight">
            <img src={PieChartIllustration} alt="" style={{ width: 360 }} />
          </div>
        </div>
        <Typography
          style={{ marginTop: 24, color: colorPicker("neutral.800") }}
        >
          We need few information of your <b>4 entity</b>, please Take action
          now to secure your tax savings and avoid potential losses.
        </Typography>
        <div className="StepContainer">
          <div className="StepBox">
            <div className="StepStatus">
              {onboardingMeta?.gstcreds?.length > 0 ? (
                <Tag color="green">Completed</Tag>
              ) : (
                <Tag color="orange">Pending</Tag>
              )}
            </div>
            <Typography>
              GST Portal Integration, to auto-magically fetch GST Returns
            </Typography>

            <Button
              style={{ width: "100%", marginTop: 60 }}
              type="primary"
              onClick={() => navigate("/credential/gst/add/pan")}
            >
              Let's find out
            </Button>
            {/* <Button style={{ width: "100%", marginTop: 12 }}>
              Invite your TMC
            </Button> */}
          </div>
          <div className="StepBox">
            <div className="StepStatus">
              {onboardingMeta?.aircreds?.length > 0 ? (
                <Tag color="green">Completed</Tag>
              ) : (
                <Tag color="orange">Pending</Tag>
              )}
            </div>
            <Typography>
              Integration to airline portals & your emails
            </Typography>

            <Button
              style={{ width: "100%", marginTop: 60 }}
              type="primary"
              onClick={() => navigate("/credential/airline/start")}
            >
              Let's find out
            </Button>
            {/* <Button style={{ width: "100%", marginTop: 12 }}>
              Invite your TMC
            </Button> */}
          </div>

          <div className="StepBox">
            <div className="StepStatus">
              {userDetails?.currentWorkspace?.meta?.onboarding?.includes(
                "TMC"
              ) ? (
                <Tag color="green">Completed</Tag>
              ) : (
                <Tag color="orange">Pending</Tag>
              )}
            </div>
            <Typography>
              GST Portal Integration, to auto-magically fetch GST Returns
            </Typography>

            <Button style={{ width: "100%", marginTop: 60 }} type="primary">
              Let's find out
            </Button>
            {/* <Button style={{ width: "100%", marginTop: 12 }}>
              Invite your TMC
            </Button> */}
          </div>
        </div>
        <div className="CertificateContainer">
          <img src={ISOCert} alt="ISO Image" />
          <img
            src={GDPRCert}
            alt="ISO Image"
            style={{ marginLeft: 48, marginRight: 48 }}
          />
          <img src={SOCCert} alt="ISO Image" />
        </div>
      </div>
      {/* <Modal
        open={true}
        onCancel={() => setShowModal(false)}
        footer={false}
        closable={false}
      >
        <AutoLogin />
      </Modal> */}
    </div>
  );
}
