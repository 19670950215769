import Typography from "app/shared/Typography";
import "./GSTAddReview.scss";
import { colorPicker } from "app/utils/color.helper";
import { Carousel } from "react-responsive-carousel";
import AirOnboarIllustration1 from "static/images/illustration/AirOnboardIllustration1.svg";
import AirOnboarIllustration2 from "static/images/illustration/AirOnboardIllustration2.svg";
import AirOnboarIllustration3 from "static/images/illustration/AirOnboardIllustration3.svg";
import ISOCert from "static/images/ISO.svg";
import GDPRCert from "static/images/GDPR.svg";
import SOCCert from "static/images/SOC.svg";
import { Button, Checkbox, notification, Tag, Upload } from "antd";
import {
  SwapOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  ArrowRightOutlined,
  SendOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
// @ts-ignore
import DatamapsIndia from "react-datamaps-india";

import { AgTableClient } from "app/shared/AgTable";
import {
  STATE_GSTIN_ICON,
  stateCodeMap,
} from "app/shared/AgTable/stateIconMap";
import Uploader from "app/shared/Uploader";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FileOutlined } from "@ant-design/icons";
import { apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_GST_CREDENTIAL_ADD_PAN_GSTIN,
  API_ENDPOINT_GST_CREDENTIAL_PAN_GSTINS,
} from "../../gstcredflow.constants";
import Navbar from "app/scenes/VendorFollowUp/components/Navbar/Navbar";
import Header1 from "app/scenes/VendorFollowUp/components/Header/Header1";
import Loader from "app/shared/Loader";

export default function GSTEntityView2(props: any) {
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  const [selectedEntity, setSelectedEntity] = useState("ABCA992212A");
  const [gstins, setGstins] = useState([]);
  const [pans, setPans] = useState<any[]>([]);
  const [panGstin, setPanGstin] = useState<any>({});
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  const [fetchingGstin, setFetchingGstin] = useState(false);
  const stateInfo = location.state;

  useEffect(() => {
    if (stateInfo?.pan) {
      setPans(stateInfo.pan);
      fetchPansGstin(stateInfo.pan);
      setSelectedEntity(stateInfo.pan?.[0]);
    }
  }, []);

  const fetchPansGstin = async (panNumbers: string[]) => {
    setFetchingGstin(true);

    // Initialize a temporary object to store GSTIN data
    const gstinData: Record<string, { gstin: string }[]> = {};

    try {
      // Iterate over the array of PAN numbers
      for (const panNum of panNumbers) {
        const response = await apiPost(API_ENDPOINT_GST_CREDENTIAL_PAN_GSTINS, {
          pan: panNum,
        });

        // If the response is successful, update the GSTIN data
        if (response.status) {
          gstinData[panNum] = response.data.gstinResList || [];
        } else {
          console.warn(`Failed to fetch GSTIN for PAN: ${panNum}`);
        }
      }

      // Update the state with all fetched GSTIN data
      setPanGstin((prev: any) => ({ ...prev, ...gstinData }));
    } catch (error) {
      console.error("Error fetching GSTINs:", error);
    } finally {
      setFetchingGstin(false);
    }
  };

  const getMapData = () => {
    let mapObject: any = {};
    (panGstin?.[selectedEntity] || []).forEach((gstInfo: any) => {
      mapObject[stateCodeMap[gstInfo.gstin?.substring(0, 2)]] = 1;
    });
    return mapObject;
  };

  const handlePanAndRedirect = async () => {
    const response = await apiPost(
      API_ENDPOINT_GST_CREDENTIAL_ADD_PAN_GSTIN,
      panGstin
    );
    if (response.status) {
      navigate("/credential/gst/add/review/final", { state: { pans: pans } });
    }
  };

  return (
    <>
      <Navbar />
      <Header1 />
      <div className="GSTAddReview">
        <div className="MainContainer">
          <div className="LeftContainer">
            <div className="MapContainer">
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Let’s find how much your entities can save for{" "}
                {panGstin?.[selectedEntity]?.length} GSTINs
              </Typography>
              <DatamapsIndia
                regionData={getMapData()}
                hideLegend={true}
                mapLayout={{
                  startColor: "#FF0000",
                  endColor: "#FF0000",
                  hoverColor: colorPicker("primary.600"),
                  noDataColor: "#fff", // Color for regions with no data
                  borderColor: "#8D8D8D", // Border color of regions
                  hoverBorderColor: colorPicker("primary.700"),
                }}
                showStatus={false}
                style={{ height: "100%", width: "100%" }}
                hoverComponent={({ value }: any) => {
                  return (
                    <>
                      <p>{value.name}</p>
                      <p>{value.value}</p>
                    </>
                  );
                }}
              />
            </div>
            <Typography
              variant="p"
              style={{ textAlign: "center", marginBottom: "16px" }}
            >
              Get started with easy GST filings, ITC tracking, and automated
              invoice management in just a few steps.
            </Typography>
            <div className="CertificateContainer">
              <img src={ISOCert} alt="ISO Image" />
              <img
                src={GDPRCert}
                alt="ISO Image"
                style={{ marginLeft: 48, marginRight: 48 }}
              />
              <img src={SOCCert} alt="ISO Image" />
            </div>
          </div>
          <div className="RightContainer">
            <div className="AirlineStart">
              <Tag color="cyan">Integration</Tag>
              <Typography variant="h5" style={{ marginTop: 12 }}>
                {pans?.length} entities added, Are you associated with more
                entities?
              </Typography>

              <div className="EntityBoxContainer">
                {pans.map((item: any, key: any) => {
                  return (
                    <div
                      key={key}
                      className="EntityBox"
                      style={{
                        border:
                          selectedEntity === item
                            ? `2px solid ${colorPicker("primary.700")}`
                            : "1px solid #dfe0e7",
                      }}
                      onClick={() => setSelectedEntity(item)}
                    >
                      <Typography
                        style={{ color: colorPicker("primary.700") }}
                        variant="caption"
                      >
                        {workspaceName || "Not Available"}
                      </Typography>
                      <div className="EntityBoxFooter">
                        <Typography style={{}} variant="xs">
                          PAN{" "}
                          <b style={{ color: colorPicker("primary.700") }}>
                            {item}
                          </b>
                        </Typography>
                        {fetchingGstin ? (
                          <LoadingOutlined />
                        ) : (
                          <Typography style={{ marginLeft: 12 }} variant="xs">
                            GSTINs{" "}
                            <b style={{ color: colorPicker("primary.700") }}>
                              {panGstin?.[item]?.length || "-"}
                            </b>
                          </Typography>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="QuestionareContainer" style={{ marginTop: 48 }}>
                <div
                  className={`QuestionBox ${
                    selectedQuestion === 1 ? "--selected" : ""
                  }`}
                  onClick={() => {
                    navigate("/credential/gst/add/pan", {
                      state: { pan: pans },
                    });
                  }}
                >
                  <div className="LeftQuestion">
                    <div className="IconBox">
                      <FileOutlined
                        style={{ color: colorPicker("primary.700") }}
                      />
                    </div>
                    <div className="QuestionMeta">
                      <Typography
                        variant="h6"
                        style={{ color: colorPicker("primary.800") }}
                        weight={500}
                      >
                        Yes, lets add another PAN/GSTIN
                      </Typography>
                      <Typography
                        variant="xs"
                        style={{ color: colorPicker("primary.700") }}
                      >
                        I have the necessary credentials to access the system
                        and ensure compliance with all requirements.
                      </Typography>
                    </div>
                  </div>
                  <div className="RightQuestion">
                    <ArrowRightOutlined
                      style={{ color: colorPicker("primary.700") }}
                    />
                  </div>
                </div>
                <div
                  className={`QuestionBox ${
                    selectedQuestion === 2 ? "--selected" : ""
                  }`}
                  onClick={handlePanAndRedirect}
                >
                  <div className="LeftQuestion">
                    <div className="IconBox">
                      <FileOutlined
                        style={{ color: colorPicker("primary.700") }}
                      />
                    </div>
                    <div className="QuestionMeta">
                      <Typography
                        variant="h6"
                        style={{
                          color: colorPicker(
                            selectedQuestion === 2
                              ? "primary.800"
                              : "neutral.800"
                          ),
                        }}
                        weight={500}
                      >
                        Let’s find how much I can save first
                      </Typography>
                      <Typography
                        variant="xs"
                        style={{
                          color: colorPicker(
                            selectedQuestion === 2
                              ? "primary.800"
                              : "neutral.700"
                          ),
                        }}
                      >
                        My access is restricted due to missing credentials.
                        Could you provide help to proceed further.
                      </Typography>
                    </div>
                  </div>
                  <div className="RightQuestion">
                    <ArrowRightOutlined
                      style={{ color: colorPicker("primary.700") }}
                    />
                  </div>
                </div>
              </div>

              {/* <Button
                style={{ width: "100%", marginTop: 42 }}
                type="primary"
                icon={<ArrowRightOutlined />}
                onClick={() => navigate("/credential/airline/question")}
              >
                Continue
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
