import React, { useEffect, useRef, useState } from 'react';
import './BhilosaDashboard.scss';
import { AgCharts } from 'ag-charts-enterprise';
// import 'ag-grid-enterprise';
import BhilosaLogo from '../../../assets/images/BhilosaLogo.png'
import FinkraftLogo from '../../../assets/images/finkraftlogo.png'
import { Button } from 'antd';
import tickIcon from '../../../assets/images/tick.png';
import phoneIcon from '../../../assets/images/phone.png';
import GstinLogo from '../../../assets/images/GstinLogo.png';
import GSTPortal from '../../../assets/images/GSTPage.png';
import warninging from '../../../assets/images/warning.png';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_ENDPOINT_GET_USER_INFO } from 'app/scenes/Auth/auth.constants';
import { apiGet } from 'app/services/apiServices';
import './App.css'
import { useRecoilState } from 'recoil';
import { workspaceInfo } from 'app/config/States/users';


const GSTIntegration = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { state } = useLocation();
  const [workspace, setWorkspace] = useRecoilState(workspaceInfo);
  const { GstinStatus, workspace: selectedWorkspaceFromLocation } = state || {};


  const [selectedWorkspace, setSelectedWorkspace] = useState(
    workspace || selectedWorkspaceFromLocation || null
  );

  const hasGstin = true;


  const [counts, setCounts] = useState({ exempted: 0, eligible: 0, notEligible: 0 });
  const [percentages, setPercentages] = useState({ exempted: 0, eligible: 0, notEligible: 0 });

  const chartRef = useRef(null);

  const [totalVendors, setTotalVendors] = useState(0);
  const [missingContactCount, setMissingContactCount] = useState(0);
  const [compliantVendors, setCompliantVendors] = useState(0);
  const [nonCompliantVendors, setNonCompliantVendors] = useState(0);


  // Save selected workspace to sessionStorage whenever it changes
  useEffect(() => {
    if (selectedWorkspace) {
      console.log("workspce--------->", selectedWorkspace)
      setWorkspace(selectedWorkspace)
    }
  }, [selectedWorkspace]);


  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch(
          `https://invoice-followup.finkraftai.com/get-eligibility-count?workspace=${workspace}`
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log('API Data:', data);  // Log the full API response

        // Check that counts and percentages are returned as expected
        setCounts({
          exempted: data.counts?.exempted || 0,
          eligible: data.counts?.eligible || 0,
          notEligible: data.counts?.not_eligible || 0,
        });

        setPercentages({
          exempted: data.percentages?.exempted || 0,
          eligible: data.percentages?.eligible || 0,
          notEligible: data.percentages?.not_eligible || 0,
        });
        setWorkspace(workspace)
      } catch (err) {
        console.error('Error fetching counts:', err); // Log errors here
        setCounts({})
        setPercentages({})
      }
    };

    fetchCounts();
  }, [workspace]);  // Fetch counts only on component mount

  useEffect(() => {
    let chartInstance;

    if (counts && percentages && counts.exempted !== undefined) {
      const { exempted, eligible, notEligible } = counts;
      const totalVendors = exempted + eligible + notEligible; // Calculate total vendors

      if (chartRef.current) {
        chartInstance = AgCharts.create({
          container: chartRef.current,
          data: [
            { category: 'Eligible', value: eligible, label: `${percentages.eligible}%` },
            { category: 'Not-eligible', value: notEligible, label: `${percentages.notEligible}%` },
            { category: 'Exempted', value: exempted, label: `${percentages.exempted}%` },
          ],
          series: [
            {
              type: 'donut',
              angleKey: 'value',
              labelKey: 'category',
              fills: ['#006D77', '#83C5BE', '#EDF6F9'],
              strokeWidth: 0,
              innerRadiusOffset: -50,
              innerLabels: [
                {
                  text: totalVendors.toString(), // Dynamic total vendors count
                  fontSize: 20,
                  color: 'black',
                },
                {
                  text: 'Vendors', // Label
                  fontSize: 12,
                  color: '#718096',
                },
              ],
              tooltip: {
                renderer: (params) => {
                  return {
                    content: `${params.datum.category}: ${params.datum.label}`,
                  };
                },
              },
            },
          ],
          legend: {
            enabled: true, // Enable the legend
            position: 'right', // Legend is placed on the right
            spacing: 20, // Adjust spacing between legend items
            item: {
              marker: {
                shape: 'circle',
                size: 12, // Adjust size of the circle in the legend
                strokeWidth: 0,
              },
              paddingX: 10, // Space between marker and text
              paddingY: 12, // Space between legend items
              label: {
                fontSize: 14,
                color: '#4A5568',
                formatter: ({ datum }) => {
                  if (datum) {
                    // Format legends as: "Category: Count"
                    return `${datum.category}: ${datum.value}`;
                  }
                  return '';
                },
              },
            },
          },
          background: {
            fill: 'transparent',
          },
          padding: {
            right: 50, // Adjust padding to accommodate legend
          },
        });
      }
    }

    // Clean up chart instance on unmount or re-render
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [counts, percentages]); // Re-render when counts or percentages change



  const OnboardingView = () => (
    <div className="onboarding-dashboard">
      <div className='dashboard-header'>
        <img src={FinkraftLogo} alt='logo' />
        <div className='dashboard-right-header'>
          <div className="vendorfollowup-dropdown-container" style={{marginTop:'45px'}}>
            <div
              className={`vendorfollowup-dropdown-header ${isDropdownOpen ? "active" : ""
                }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedWorkspace || "Select a workspace"}
              <span className="vendorfollowup-dropdown-icon">
                {isDropdownOpen ? "▲" : "▼"}
              </span>
            </div>

            {isDropdownOpen && (
              <div className="vendorfollowup-dropdown-body">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search workspaces..."
                  className="vendorfollowup-dropdown-search"
                />
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((workspace) => (
                    <div
                      key={workspace.id}
                      className="vendorfollowup-dropdown-item"
                      onClick={() => handleSelectOption(workspace)}
                    >
                      {workspace.name}
                    </div>
                  ))
                ) : (
                  <div className="vendorfollowup-dropdown-item no-options">
                    No workspaces found
                  </div>
                )}
              </div>
            )}
          </div>
          <Button className='header-btn' >
            <img src={phoneIcon} alt='' style={{ width: '19px', padding: '0px' }} />
            Contact </Button>
        </div>

      </div>

      <div className="content-grid">
        {/* First Row */}
        <div className="card integration-section">
          <span className="integration-badge">Integration</span>

          <div className="title-container">
            <img src={GstinLogo} alt='logo' style={{ width: "35px", marginTop: '5px' }} />
            <h2>GST integration</h2>
          </div>

          <p className="description">
            We will match your invoices with GSTR 2B and generate a report for the claimable amount.
            To proceed, we require you to authenticate with GST Portal Credentials.
          </p>

          <div className="button-group">
            <button className="invite-button">
              <span>→</span>
              Invite user who has credentials
            </button>
            <button className="add-pan-button">Add PAN</button>
          </div>

          <div className="info-text">
            ⓘ Invite user who has credentials
          </div>
        </div>

        <div className="card portal-preview">
          <img
            src={GSTPortal}
            alt="GST Portal"
          />
        </div>

        {/* Second Row */}
        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Summary</h3>

          <p className="content">
            Stay ahead of GST regulations with a secure platform that provides real-time
            insights into your vendor ecosystem with seamless GST integration, monitor
            e-invoice eligibility in single place.
          </p>

          <Button type="primary" disabled style={{ width: '100%', borderRadius: '8px', background: '#9DCDD4', color: '#fff', border: 'none' }}>
            Continue →
          </Button>
        </div>

        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Vendor Management</h3>

          <ul className="vendor-list">
            <li>Monitor GST return filing status of all vendors.</li>
            <li>Tag and categorise vendors based on compliance.</li>
            <li>Automated notifications to vendors for compliance lapses.</li>
          </ul>

          <Button type="primary" disabled style={{ width: '100%', borderRadius: '8px', background: '#9DCDD4', color: '#fff', border: 'none' }}>
            Continue →
          </Button>
        </div>
      </div>
    </div>
  );


  const navigate = useNavigate();

  const handleNavigation = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate(`/vendorfollowup/vendoreinvoice?workspace=${workspace}`);
  };

  const summarypage = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate(`/credential/gst/add/review/final`);
  };

  const handleNavigation1 = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate(`/vendorfollowup/vendorlist?workspace=${workspace}`);
  };

  const handleGstIntegration = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate("/vendorfollowup/invitepage");
  };

  const handleAddPan = () => {
    // Navigate to the next page and pass 'pan' as state
    navigate(`/credential/gst/add/pan`, { state: { pan: '' } });
  };
  

  useEffect(() => {
    // Fetch vendor data
    axios.get(`https://invoice-followup.finkraftai.com/vendor-data?workspace=${workspace}`)
      .then(response => {
        const vendorList = response.data.map(vendor => ({
          vendorName: vendor.enablement?.legalNm || '',
          gstin: vendor.gstin,
          eInvoice: vendor.einvoiceStatus,
          invoiceStatus: vendor.invoiceStatus, // Assuming invoiceStatus exists
        }));

        setTotalVendors(vendorList.length); // Set total vendors count

        // Calculate compliant and non-compliant vendors
        const compliant = vendorList.filter(
          vendor => vendor.invoiceStatus === "Invoice Received" && vendor.eInvoice === "Yes"
        ).length;
        setCompliantVendors(compliant);

        const nonCompliant = vendorList.filter(
          vendor => vendor.invoiceStatus === "Invoice Not Received" && vendor.eInvoice === "Yes"
        ).length;
        setNonCompliantVendors(nonCompliant);

        // Fetch contact data
        axios.get(`https://invoice-followup.finkraftai.com/get-contacts?workspace=${workspace}`)
          .then(contactResponse => {
            const contactData = contactResponse.data;

            // Update missing contact count
            const missingContacts = vendorList.filter(vendor =>
              !contactData.find(contact => contact.vendorName === vendor.vendorName && contact.gstin === vendor.gstin)
            );
            setMissingContactCount(missingContacts.length); // Set missing contacts count
          })
          .catch(contactError => {
            console.error('Error fetching contact data:', contactError);
          });


      })
      .catch(error => {
        console.error('Error fetching vendor data:', error);

      });
  }, [workspace]);
  const [entities, setEntities] = useState([]);

  // Fetch data in useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://invoice-followup.finkraftai.com/fetch-gstins?workspace_name=${workspace}`
        );
        if (response.status === 200) {
          // Map the response data into the format required by the UI
          const formattedData = response.data.map((item) => ({
            pan: item.pan,
            tradeName: item.trade_name,
            gstin: item.gstin_count,
            credStatus: "✔ Completed", // Default Cred Status as Active
          }));
          setEntities(formattedData);
        }
      } catch (error) {
        console.error("Error fetching entities:", error);
        setEntities([]);
      }
    };


    fetchData();

  }, [workspace]);


  // const entities = [
  //   {
  //     pan: 'AA34567807',
  //     tradeName: 'BHILOSA INDIA PRIVATE LIMITED',
  //     gstin: '06',
  //     status: 'Active',
  //     credStatus: '✔ Completed'
  //   },
  //   {
  //     pan: 'AC34567812',
  //     tradeName: 'Bhilosa Ind limited',
  //     gstin: '04',
  //     status: 'Active',
  //     credStatus: '⦶ Pending'
  //   }
  // ];


  const [gstAmountData, setGstAmountData] = useState(null);



  useEffect(() => {
    // Define the API URL
    const apiUrl = 'https://invoice-followup.finkraftai.com/sum-gst-eligible';

    // Call the API
    const fetchGstData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ workspaceName: workspace }), // Send workspace name in the payload
        });

        if (response.ok) {
          const data = await response.json();
          setGstAmountData(data); // Store the response data
        } else {
          console.error('Failed to fetch GST data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchGstData();
  }, [workspace]);



  useEffect(() => {
    // Fetch workspace data on mount
    const fetchUserInfo = async () => {
      try {
        const response = await apiGet(API_ENDPOINT_GET_USER_INFO, false);
        console.log("API Response:", response);
        if (response.status && response.data?.workspaces) {
          setWorkspaces(response.data.workspaces);
          setFilteredOptions(response.data.workspaces); // Initialize filtered options
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  // Filter options based on the search query
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredOptions(
      workspaces.filter((workspace) =>
        workspace.name.toLowerCase().includes(query)
      )
    );
  };

  // Handle selection of a workspace
  const handleSelectOption = (workspace) => {
    setSelectedWorkspace(workspace.name);
    setIsDropdownOpen(false);
    setSearchQuery("");
    setFilteredOptions(workspaces); // Reset the filtered options
  };
  return hasGstin ? (
    <div className="dashboard">
      <div className='dashboard-header'>
        <img src={FinkraftLogo} alt='logo' className='finklogo' />
        <div className='dashboard-right-header'>
          <div className="vendorfollowup-dropdown-container" style={{marginTop:'25px'}}>
            <div
              className={`vendorfollowup-dropdown-header ${isDropdownOpen ? "active" : ""
                }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedWorkspace || "Select a workspace"}
              <span className="vendorfollowup-dropdown-icon">
                {isDropdownOpen ? "▲" : "▼"}
              </span>
            </div>

            {isDropdownOpen && (
              <div className="vendorfollowup-dropdown-body">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search workspaces..."
                  className="vendorfollowup-dropdown-search"
                />
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((workspace) => (
                    <div
                      key={workspace.id}
                      className="vendorfollowup-dropdown-item"
                      onClick={() => handleSelectOption(workspace)}
                    >
                      {workspace.name}
                    </div>
                  ))
                ) : (
                  <div className="vendorfollowup-dropdown-item no-options">
                    No workspaces found
                  </div>
                )}
              </div>
            )}
          </div>
          <Button className='header-btn' >
            <img src={phoneIcon} alt='' style={{ width: '19px', padding: '0px' }} />
            Contact
          </Button>
        </div>
      </div>
      <div className='container-first-row'>
        <div className="header">
          <span className="integration-badge">Integration</span>

          <div className="title-container">
            {/* <div className="icon">G</div> */}
            <img src={GstinLogo} alt='logo' style={{ width: "35px", marginTop: '5px' }} />
            <h2>GST integration</h2>
          </div>

          <p className="description">
            We will match your invoices with GSTR 2B and generate a report for the claimable amount.
            To proceed, we require you to authenticate with GST Portal Credentials.
          </p>

          <div className="button-group">
            <button className="invite-button" onClick={handleGstIntegration}>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M4 12h16M12 4l8 8-8 8" />
              </svg>
              Invite user who has credentials
            </button>

            <button className="add-pan-button" onClick={handleAddPan}>
              Add PAN
            </button>
          </div>

          <div className='first-row-info'>
            ⓘ Invite user who has credentials
          </div>
        </div>

        <div className="entities-section">
          <div className="entities-header">
            <h3>{entities.length} entities added, Are you associated with more entities?</h3>
            <button className="view-detail" onClick={summarypage}>View in detail ↗ </button>
          </div>


          <div className="entities-list">
            {entities.map((entity) => (
              <div key={entity.pan} className="entity-card">
                <div className="entity-header">
                  <img src={tickIcon} alt='tick' />
                  <span className="pan-label">PAN :</span>
                  <span className="pan-value">{entity.pan}</span>
                  <span className="status-active">● Active</span>
                </div>

                <div className='underline-1'></div>

                <div className="entity-details">
                  <div className="detail-group">
                    <p className="label">Trade name :</p>
                    <p className="value">{entity.tradeName}</p>
                  </div>
                  <div className="detail-group">
                    <p className="label">GSTIN's :</p>
                    <p className="value">{entity.gstin}</p>
                  </div>
                  <div className="detail-group">
                    <p className="label">Cred Status :</p>
                    <span className={`status-badge ${entity.credStatus.toLowerCase()}`}>
                      {entity.credStatus}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* {gstAmountData ? (
            <div style={{ marginTop: '30px', color: '#0A8394' }}>
              <div className="gst-info">
                <p><strong style={{ color: '10px' }}>Total GST Amount:</strong> {gstAmountData.totalGstAmountSum}</p>
              </div>
            </div>
          ) : (
            <p className="no-data">No GST data available</p> // Display message when no data is available
          )} */}
        </div>
      </div>

      <div className="container-second-row">
         {/* Vendors Management Section */}
         <section className="dashboard-section" onClick={handleNavigation}>
          <div className="section-header">
            <div className="header-tabs">
              <button className="tab-button active">Immediate action</button>
            </div>

          </div>

          <div className='section-subheading'>
            <div>Follow-ups  </div>
            <a href="#" className="view-detail">View in detail ↗</a>
          </div>

          <div className='Amount-card'>
  <div className='Amount-card-left'>
    <img src={warninging} />
    <p>Total Amount at risk :</p>
  </div>
  <div className='Amount-card-right'>
    {gstAmountData ? (
      <h1>₹ {gstAmountData.totalGstAmountSum.toLocaleString('en-IN')}</h1>
    ) : (
      <h1>₹ No GST data available</h1>
    )}
  </div>
</div>


          {/* <div className='pan-amount-cards'>
            <div className='pan-amount-card'>
            <div className="entity-header">
                  <span className="pan-label">PAN :</span>
                  <span className="pan-value">234567654567</span>
                  <span className="status-active">● Active</span>
                </div>

                <div className='underline-1'></div>

                <div className='pan-amount-div'>
                  <h1 className='pan-amount-text'>56,78,765<span className='pan-span'>INR</span></h1>
                </div>
            </div>
            <div className='pan-amount-card'>
            <div className="entity-header">
                  <span className="pan-label">PAN :</span>
                  <span className="pan-value">234567654567</span>
                  <span className="status-active">● Active</span>
                </div>

                <div className='underline-1'></div>

                <div className='pan-amount-div'>
                  <h1 className='pan-amount-text'>56,78,765<span className='pan-span'>INR</span></h1>
                </div>
            </div>
          </div> */}


        </section>
        {/* Overview Section */}
        <section className="dashboard-section" onClick={handleNavigation1}>
          <div className="section-header">
            <div className="header-tabs">
              <button className="tab-button active">Overview</button>
            </div>
          </div>

          <div className='section-subheading'>
            <div> Summary </div>
            <a href="#" className="view-detail">View in detail ↗</a>
          </div>

          <div className="section-content">
            <div className="eligibility-chart">
              <div className='chart-heading'>Vendor E-invoice eligibility</div>
              <div className="chart-container">
                <div ref={chartRef} style={{ width: '70%', height: '255px' }} />
                <div className="custom-legend">
                  <ul>
                    <li>
                      <span className="legend-marker" style={{ backgroundColor: '#006D77' }}></span>
                      Eligible: {counts.eligible}
                    </li>
                    <li>
                      <span className="legend-marker" style={{ backgroundColor: '#83C5BE' }}></span>
                      Not-eligible: {counts.notEligible}
                    </li>
                    <li>
                      <span className="legend-marker" style={{ backgroundColor: '#EDF6F9' }}></span>
                      Exempted: {counts.exempted}
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            {/* <div className="msme-stats">
            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-icon total">T</div>
                <div className="stat-title">Total vendors</div>
                <button className="more-options">⋮</button>
              </div>
              <div className='underline'></div>

              <div className="stat-info">
                <div className="stat-value">{totalVendors}</div>
                <div className="stat-subtitle">vendors</div>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-icon missing">M</div>
                <div className="stat-title">Missing vendors contact</div>
                <button className="more-options">⋮</button>
              </div>
              <div className='underline'></div>
              <div className="stat-info">
                <div className="stat-value">{missingContactCount}</div>
                <div className="stat-subtitle">vendors</div>
              </div>
            </div>
            </div> */}
          </div>
        </section>

       
      </div>
    </div>
  ) : (
    <OnboardingView />
  );
};

export default React.memo(GSTIntegration);;