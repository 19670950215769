import PageHeader from "app/shared/PageHeader";
import { useLocation } from "react-router-dom";
import "./ReportLegacyView.scss";
export default function ReportLegacyView(props: any) {
  const location = useLocation();
  const { report_link } = location.state;

  return (
    <div className="ReportLegacyView" style={{ height: "100%" }}>
      <div className="PageHeaderContainer">
        <PageHeader title="Report View" goBack />
      </div>
      <div className="MainContainer">
        <iframe
          height="100%"
          style={{ width: "100%" }}
          scrolling="no"
          title="Iframe"
          src={report_link}
        ></iframe>
      </div>
    </div>
  );
}
