import React, { useEffect, useRef } from 'react';
import './BhilosaDashboard.scss';
import { AgCharts } from 'ag-charts-enterprise';
// import 'ag-grid-enterprise';
import BhilosaLogo from '../../assets/images/BhilosaLogo.png'
import FinkraftLogo from '../../assets/images/finkraft_logo.png'
import { Button } from 'antd';
import tickIcon from '../../assets/images/tick.png';
import phoneIcon from '../../assets/images/phone.png';
import GstinLogo from '../../assets/images/GstinLogo.png';
import GSTPortal from '../../assets/images/GSTPage.png';

const GSTIntegration = () => {

  const hasGstin = true; // mock data you need to replace this with a prop having gstin data


  const entities = [
    {
      pan: 'AA34567807',
      tradeName: 'BHILOSA INDIA PRIVATE LIMITED',
      gstin: '06',
      status: 'Active',
      credStatus: '✔ Completed'
    },
    {
      pan: 'AC34567812',
      tradeName: 'Bhilosa Ind limited',
      gstin: '04',
      status: 'Active',
      credStatus: '⦶ Pending'
    }
  ];

  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance;

    if (chartRef.current) {
      chartInstance = AgCharts.create({
        container: chartRef.current,
        data: [
          { category: 'Eligible', value: 300, label: '70%' },
          { category: 'Not-eligible', value: 10, label: '10%' },
          { category: 'Exempted', value: 20, label: '20%' },
        ],
        series: [
          {
            type: 'donut',
            angleKey: 'value',
            labelKey: 'category',
            fills: ['#006D77', '#83C5BE', '#EDF6F9'],
            strokeWidth: 0,
            innerRadiusOffset: -50,
            innerLabels: [
              {
                text: '330',
                fontSize: 20,
                color: 'black',
              },
              {
                text: 'Vendors',
                fontSize: 12,
                color: '#718096',
              },
            ],
          },
        ],
        legend: {
          position: 'right',
          spacing: 40,
          item: {
            marker: {
              shape: 'circle',
              size: 10,
              strokeWidth: 0,
            },
            paddingY: 8,
            label: {
              fontSize: 14,
              color: '#4A5568',
              formatter: ({ datum }) => {
                if (datum) {
                  // Add padding between category and value
                  const category = datum.category.padEnd(12, ' ');
                  return `${category}${datum.value}`;
                }
                return '';
              },
            },
          },
        },
        background: {
          fill: 'transparent',
        },
        padding: {
          right: 100,
        },
      });
    }

    // Clean up chart instance on unmount or re-render
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, []);



  const OnboardingView = () => (
    <div className="onboarding-dashboard">
      <div className='dashboard-header'>
        <img src={FinkraftLogo} alt='logo' />
        <Button className='header-btn' >
          <img src={phoneIcon} alt='' style={{ width: '19px', padding: '0px' }} />
          Contact </Button>
      </div>

      <div className="content-grid">
        {/* First Row */}
        <div className="card integration-section">
          <span className="integration-badge">Integration</span>

          <div className="title-container">
            <img src={GstinLogo} alt='logo' style={{ width: "35px", marginTop: '5px' }} />
            <h2>GST integration</h2>
          </div>

          <p className="description">
            We will match your invoices with GSTR 2B and generate a report for the claimable amount.
            To proceed, we require you to authenticate with GST Portal Credentials.
          </p>

          <div className="button-group">
            <button className="invite-button">
              <span>→</span>
              Invite user who has credentials
            </button>
            <button className="add-pan-button">Add PAN</button>
          </div>

          <div className="info-text">
            ⓘ Invite user who has credentials
          </div>
        </div>

        <div className="card portal-preview">
          <img
            src={GSTPortal}
            alt="GST Portal"
          />
        </div>

        {/* Second Row */}
        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Summary</h3>

          <p className="content">
            Stay ahead of GST regulations with a secure platform that provides real-time
            insights into your vendor ecosystem with seamless GST integration, monitor
            e-invoice eligibility in single place.
          </p>

          <Button type="primary" disabled style={{ width: '100%', borderRadius: '8px', background: '#9DCDD4', color: '#fff', border: 'none' }}>
            Continue →
          </Button>
        </div>

        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Vendor Management</h3>

          <ul className="vendor-list">
            <li>Monitor GST return filing status of all vendors.</li>
            <li>Tag and categorise vendors based on compliance.</li>
            <li>Automated notifications to vendors for compliance lapses.</li>
          </ul>

          <Button type="primary" disabled style={{ width: '100%', borderRadius: '8px', background: '#9DCDD4', color: '#fff', border: 'none' }}>
            Continue →
          </Button>
        </div>
      </div>
    </div>
  );



  return hasGstin ? (
    <div className="dashboard">
      <div className='dashboard-header'>
        <img src={FinkraftLogo} alt='logo' />
        <Button className='header-btn' >
          <img src={phoneIcon} alt='' style={{ width: '19px', padding: '0px' }} />
          Contact </Button>
      </div>
      <div className='container-first-row'>
        <div className="header">
          <span className="integration-badge">Integration</span>

          <div className="title-container">
            {/* <div className="icon">G</div> */}
            <img src={GstinLogo} alt='logo' style={{ width: "35px", marginTop: '5px' }} />
            <h2>GST integration</h2>
          </div>

          <p className="description">
            We will match your invoices with GSTR 2B and generate a report for the claimable amount.
            To proceed, we require you to authenticate with GST Portal Credentials.
          </p>

          <div className="button-group">
            <button className="invite-button">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M4 12h16M12 4l8 8-8 8" />
              </svg>
              Invite user who has credentials
            </button>

            <button className="add-pan-button">
              Add PAN
            </button>
          </div>

          <div className='first-row-info'>
            ⓘ Invite user who has credentials
          </div>
        </div>

        <div className="entities-section">
          <div className="entities-header">
            <h3>{entities.length} entities added, Are you associated with more entities?</h3>
            <button className="view-detail">View in detail ↗ </button>
          </div>


          <div className="entities-list">
            {entities.map((entity) => (
              <div key={entity.pan} className="entity-card">
                <div className="entity-header">
                  <img src={tickIcon} alt='tick' />
                  <span className="pan-label">PAN :</span>
                  <span className="pan-value">{entity.pan}</span>
                  <span className="status-active">● Active</span>
                </div>

                <div className='underline-1'></div>

                <div className="entity-details">
                  <div className="detail-group">
                    <p className="label">Trade name :</p>
                    <p className="value">{entity.tradeName}</p>
                  </div>
                  <div className="detail-group">
                    <p className="label">GSTIN's :</p>
                    <p className="value">{entity.gstin}</p>
                  </div>
                  <div className="detail-group">
                    <p className="label">Cred Status :</p>
                    <span className={`status-badge ${entity.credStatus.toLowerCase()}`}>
                      {entity.credStatus}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-second-row">
        {/* Overview Section */}
        <section className="dashboard-section">
          <div className="section-header">
            <div className="header-tabs">
              <button className="tab-button active">Overview</button>
            </div>
          </div>

          <div className='section-subheading'>
            <div> Summary </div>
            <a href="#" className="view-detail">View in detail ↗</a>
          </div>

          <div className="section-content">
            <div className="eligibility-chart">
              <div>Vendor E-invoice eligibility</div>
              <div className="chart-container">
                <div ref={chartRef} style={{ width: '100%', height: '255px' }} />
              </div>
            </div>

            <div className="msme-stats">
              <div className="stat-bar-container">
                <h3>MSME</h3>
                <span className="stat-label" style={{ fontSize: '10px' }}>30 vendors</span>
                <div className="stat-bar">
                  <div className="stat-bar-fill" style={{ width: '10%' }}>
                  </div>
                </div>
                <div className="stat-subtitle">Out of 330</div>
              </div>

              <div className="stat-bar-container">
                <h3>Non-MSME</h3>
                <span className="stat-label" style={{ fontSize: '10px' }}>330 vendors</span>
                <div className="stat-bar">
                  <div className="stat-bar-fill" style={{ width: '100%' }}>
                  </div>
                </div>
                <div className="stat-subtitle">Out of 330</div>
              </div>
            </div>
          </div>
        </section>

        {/* Vendors Management Section */}
        <section className="dashboard-section">
          <div className="section-header">
            <div className="header-tabs">
              <button className="tab-button active">Vendors</button>
            </div>

          </div>

          <div className='section-subheading'>
            <div> Vendors Management </div>
            <a href="#" className="view-detail">View in detail ↗</a>
          </div>


          <div className="stats-grid">
            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-icon total">T</div>
                <div className="stat-title">Total vendors</div>
                <button className="more-options">⋮</button>
              </div>
              <div className='underline'></div>

              <div className="stat-info">
                <div className="stat-value">330</div>
                <div className="stat-subtitle">vendors</div>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-icon compliant">C</div>
                <div className="stat-title">Compliant vendors</div>
                <button className="more-options">⋮</button>
              </div>
              <div className='underline'></div>

              <div className="stat-info">
                <div className="stat-value">300</div>
                <div className="stat-subtitle">vendors</div>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-icon non-compliant">N</div>
                <div className="stat-title">Non-compliant vendors</div>
                <button className="more-options">⋮</button>
              </div>
              <div className='underline'></div>

              <div className="stat-info">
                <div className="stat-value">30</div>
                <div className="stat-subtitle">vendors</div>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-icon missing">M</div>
                <div className="stat-title">Missing vendors contact</div>
                <button className="more-options">⋮</button>
              </div>
              <div className='underline'></div>
              <div className="stat-info">
                <div className="stat-value">69</div>
                <div className="stat-subtitle">vendors</div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : (
    <OnboardingView />
  );
};

export default React.memo(GSTIntegration);;